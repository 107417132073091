import React, { forwardRef, useMemo } from 'react'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from './select'
import { SelectProps } from '@radix-ui/react-select'
import { format } from 'date-fns'
import { DEFAULT_DATE_FORMAT, parseDate } from '@almaris/shared/utils/dates'

const generateTimeArray = (
  startTime: string,
  endTime: string,
  step: number = 30
) => {
  const timeArray = []
  const [startHour, startMinute] = startTime.split(':').map(Number)
  const [endHour, endMinute] = endTime.split(':').map(Number)

  const start = startHour * 60 + startMinute // Convert start time to minutes
  const end = endHour * 60 + endMinute // Convert end time to minutes

  for (let i = start; i <= end; i += step) {
    const hours = Math.floor(i / 60)
    const minutes = i % 60
    const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`
    timeArray.push(formattedTime)
  }

  return timeArray
}

export type TimePickerDTO = 'HH:mm' | 'Date'

export type TimePickerProps = Omit<SelectProps, 'onValueChange' | 'value'> & {
  placeholder?: string
  className?: string
  onChange?: (value: string | Date) => void
  value?: string | Date
  startTime?: string
  endTime?: string
  step?: number
  baseDate?: Date
  dto?: TimePickerDTO
}

const TimePicker = forwardRef<HTMLSelectElement, TimePickerProps>(
  (
    {
      placeholder,
      className,
      onChange,
      startTime = '09:00',
      endTime = '19:00',
      step = 30,
      value,
      disabled,
      baseDate = new Date(),
      dto = 'HH:mm',
      ...props
    },
    ref
  ) => {
    const times = useMemo(
      () => generateTimeArray(startTime, endTime, step),
      [startTime, endTime, step]
    )

    const selectedValue = useMemo(() => {
      if (dto === 'Date') {
        const dateValue = parseDate(value, DEFAULT_DATE_FORMAT)
        if (!dateValue) {
          console.error(`Unexpected date : ${value}`)
          return undefined
        }
        return format(dateValue, 'HH:mm')
      }
      return value as string
    }, [value])

    const handleValueChange = (newValue: string) => {
      if (dto === 'Date') {
        const newDate = parseDate(baseDate, DEFAULT_DATE_FORMAT)
        if (!newDate) {
          console.error(`Unexpected date : ${baseDate}`)
          return
        }
        const [HH, mm] = newValue.split(':').map(Number)
        newDate.setHours(HH, mm, 0, 0)
        onChange?.(newDate)
      } else {
        onChange?.(newValue)
      }
    }
    return (
      <Select
        {...props}
        value={selectedValue}
        onValueChange={handleValueChange}
        disabled={disabled}
        {...ref}
      >
        <SelectTrigger
          className={['tw-w-full', className].join(' ')}
          disabled={disabled}
        >
          <SelectValue placeholder={placeholder} />
        </SelectTrigger>
        <SelectContent className="tw-flex tw-justify-items-center">
          <SelectGroup>
            {times.map((time) => (
              <SelectItem key={time} value={time}>
                {time}
              </SelectItem>
            ))}
          </SelectGroup>
        </SelectContent>
      </Select>
    )
  }
)

TimePicker.displayName = 'TimePicker'

export { TimePicker }
