import { Badge } from '@/components/ui/badge'
import { Button } from '@/components/ui/button'
import { Separator } from '@/components/ui/separator'
import { useNavigateSeller } from '@/seller/hooks/useNavigateSeller'
import { usePathInSeller } from '@/seller/hooks/usePathInSeller'
import { useGetEstateForSidebarSubscription } from '@gql'
import { useHasuraClaim } from '@nhost/react'
import {
  ArrowLeftIcon,
  BookMarkedIcon,
  CheckIcon,
  EllipsisIcon,
  FileTextIcon,
  GavelIcon,
  HomeIcon,
  ImagesIcon,
  TriangleAlertIcon,
} from 'lucide-react'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'
import { EstateSidebarAgencyMemberSelect } from './EstateSidebarAgencyMemberSelect'

type Params = {
  slug: string
}

export const EstateSidebar = () => {
  const agencyMemberId = useHasuraClaim('agency-member-id')
  const isAgencyMember = !!agencyMemberId && agencyMemberId !== 'null'
  const pathInSeller = usePathInSeller('')

  const { t } = useTranslation()
  const navigate = useNavigate()
  const navigateSeller = useNavigateSeller()
  const { slug } = useParams<Params>()
  const rootPath = slug ? pathInSeller : '/'

  const { estateId } = useParams<{ estateId: string }>()

  const { data } = useGetEstateForSidebarSubscription({
    variables: { id: estateId! },
    skip: estateId == null,
  })
  const estate = useMemo(() => data?.estate_by_pk, [data])

  return (
    <aside className="tw-border-r tw-border-zinc-200 tw-bg-zinc-50 tw-h-[calc(100vh_-_80px)] tw-flex tw-flex-col tw-justify-between">
      <div className="tw-flex tw-flex-col tw-gap-3">
        <div className="tw-flex tw-items-center tw-mt-3 tw-gap-4 tw-px-4">
          <Button
            aria-label="Retour" // TODO: translate
            variant="outline"
            size="sm"
            className="tw-flex tw-items-center tw-rounded-lg "
            onClick={() => (slug ? navigateSeller('') : navigate('/'))}
          >
            <ArrowLeftIcon size={15} />
          </Button>
          <span className="tw-text-zinc-900 tw-text-lg tw-font-semibold">
            {estate == null ? (
              <>&nbsp;</>
            ) : estate.name == null || estate.name.trim().length === 0 ? (
              `${estate.address} ${estate.zipCode} ${estate.city}`
            ) : (
              estate.name
            )}
          </span>
        </div>
        {isAgencyMember && <Separator />}
        {isAgencyMember && (
          <div className="tw-flex-1 tw-px-4 tw-flex tw-justify-center">
            {estate == null ? (
              <Badge variant="secondary" className="tw-transition-none">
                <EllipsisIcon />
                &nbsp;
              </Badge>
            ) : estate.applicationAcceptedDate != null ? (
              <Badge variant="success" className="tw-transition-none">
                <CheckIcon className="tw-mr-1" />
                {t('EstateSidebar.isApplicationAccepted')}
              </Badge>
            ) : !estate.isPublished ? (
              <Badge variant="destructive" className="tw-transition-none">
                <TriangleAlertIcon className="tw-mr-1" />
                {t('EstateSidebar.isNotPublished')}
              </Badge>
            ) : (
              <Badge variant="success" className="tw-transition-none">
                <CheckIcon className="tw-mr-1" />
                {t('EstateSidebar.isPublished')}
              </Badge>
            )}
          </div>
        )}
        <Separator />
        <div className="tw-flex-1 tw-px-4">
          <nav className="tw-grid tw-items-start tw-text-sm tw-font-medium tw-gap-4">
            <EstateSidebarLink
              to={`${rootPath}estates/${estate?.id}/sellers`}
              disabled={estate == null}
              left={<HomeIcon className="tw-stroke-zinc-400" />}
            >
              {t('EstateSidebar.sellers')}
            </EstateSidebarLink>
            <EstateSidebarLink
              to={`${rootPath}estates/${estate?.id}`}
              disabled={estate == null}
              left={<HomeIcon className="tw-stroke-zinc-400" />}
            >
              {t('EstateSidebar.data')}
            </EstateSidebarLink>
            <EstateSidebarLink
              to={`${rootPath}estates/${estate?.id}/files`}
              disabled={estate == null}
              left={<FileTextIcon className="tw-stroke-zinc-400" />}
            >
              {t('EstateSidebar.files')}
            </EstateSidebarLink>
            <EstateSidebarLink
              to={`${rootPath}estates/${estate?.id}/medias`}
              disabled={estate == null}
              left={<ImagesIcon className="tw-stroke-zinc-400" />}
            >
              {t('EstateSidebar.medias')}
            </EstateSidebarLink>
            <EstateSidebarLink
              to={`${rootPath}estates/${estate?.id}/mandate`}
              disabled={estate == null}
              left={<BookMarkedIcon className="tw-stroke-zinc-400" />}
            >
              {t('EstateSidebar.mandate')}
            </EstateSidebarLink>
            <EstateSidebarLink
              to={`${rootPath}estates/${estate?.id}/sale`}
              disabled={estate == null}
              left={<GavelIcon className="tw-stroke-zinc-400" />}
            >
              {t('EstateSidebar.sale')}
            </EstateSidebarLink>
          </nav>
        </div>
      </div>
      {estate != null && (estate.missionHeadId || isAgencyMember) && (
        <div className="tw-px-4 tw-mb-4 tw-space-y-2">
          <EstateSidebarAgencyMemberSelect estate={estate} />
        </div>
      )}
    </aside>
  )
}

const EstateSidebarLink = ({
  to,
  children,
  left,
  right,
  disabled,
}: {
  to: string
  disabled: boolean
  left?: React.ReactNode
  right?: React.ReactNode
  children: React.ReactNode
}) => {
  return (
    <NavLink
      to={to}
      className={twMerge(
        'tw-flex tw-items-center tw-justify-between tw-gap-3 tw-rounded-[8px] tw-px-4 tw-py-3 tw-text-m tw-text-zinc-900 tw-transition-all tw-border tw-border-transparent hover:tw-bg-white hover:tw-border hover:tw-border-zinc-200 hover:tw-shadow-sm   aria-[current=page]:tw-bg-white aria-[current=page]:tw-border aria-[current=page]:tw-border-zinc-200 aria-[current=page]:tw-shadow-sm',
        disabled && 'tw-pointer-events-none tw-cursor-default'
      )}
      end
    >
      <div className="tw-flex tw-items-center tw-gap-3">
        {left}
        <span>{children}</span>
      </div>
      {right}
    </NavLink>
  )
}
