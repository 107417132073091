import useIsAgencyMember from '@/agencyMember/hooks/useIsAgencyMember'
import { Loading } from '@/common/atoms/Loading'
import { Button } from '@/components/ui/button'
import { Card, CardContent } from '@/components/ui/card'
import { ConfirmationDialog } from '@/components/ui/ConfirmationDialog'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { Input } from '@/components/ui/input'
import { InputDatePicker } from '@/components/ui/InputDatePicker'
import { Label } from '@/components/ui/label'
import { useToast } from '@/components/ui/use-toast'
import { useGetPresignedUrl } from '@/file/hooks/useGetPresignedUrl'
import {
  EstateForMandatePageFragment,
  Mandate_Status_Enum,
  MandateFragment,
  useCreateMandateRegistryMutation,
  useUpdateMandateMutation,
  useUploadMandateMediaMutation,
  WebappMandateFragment,
} from '@gql'
import { useFileUpload } from '@nhost/react'
import { HourglassIcon, PencilIcon } from 'lucide-react'
import React, {
  ChangeEvent,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import {
  generateEstateLabel,
  generateObjectLabel,
  generateRepresentativesAddress,
  generateRepresentativesNames,
} from '../utils/generateRegisterNumber'

type MandateStatusCardProps = {
  mandate?: WebappMandateFragment | null
  agencyId: string
  estate: Pick<
    EstateForMandatePageFragment,
    'id' | 'type' | 'address' | 'zipCode' | 'city' | 'seller' | 'estate_sellers'
  >
}

export const MandateStatusCard = ({
  mandate,
  agencyId,
  estate,
}: MandateStatusCardProps) => {
  const { t } = useTranslation()
  const { toast } = useToast()
  const isAgencyMember = useIsAgencyMember()

  const availableSellers = useMemo(() => {
    const otherSellers = estate.estate_sellers.map((es) => es.seller)
    otherSellers.unshift(estate.seller)
    return otherSellers
  }, [estate.estate_sellers])

  const [openConfirm, setOpenConfirm] = useState(false)

  const [createMandateRegistry] = useCreateMandateRegistryMutation({
    refetchQueries: ['getMandate'], // TODO get new data from response instead of refreshing
  })

  const [updateMandate] = useUpdateMandateMutation({
    refetchQueries: ['getMandate'], // TODO get new data from response instead of refreshing
  })
  const [uploadMandate] = useUploadMandateMediaMutation({
    refetchQueries: ['getMandate'], // TODO get new data from response instead of refreshing
  })

  const lastFileId = useMemo(() => {
    const mandateMedias = [...(mandate?.mandate_media ?? [])]
    if (!mandateMedias.length) return
    mandateMedias.sort((a, b) => {
      return b.created_at.localeCompare(a.created_at)
    })
    return mandateMedias[0]?.fileId
  }, [mandate])

  const { url } = useGetPresignedUrl({
    fileId: lastFileId!,
    skip: !lastFileId,
    onError: (error) => {
      console.error(error)
      toast({
        variant: 'destructive',
        title: t('MandateStatusCard.useGetPresignedUrl.error.title'),
        description: t(
          'MandateStatusCard.useGetPresignedUrl.error.description'
        ),
      })
    },
  })

  const {
    add,
    upload,
    // cancel,
    isUploaded,
    isUploading,
    isError,
    progress,
    id,
    bucketId,
    name,
  } = useFileUpload()

  const handleMandateSubmit = async (file: File, date: Date) => {
    await uploadFile(file, date)
  }

  const uploadFile = async (file: File, date?: Date) => {
    if (!mandate) return
    add({ file })

    const params = { id, bucketId, name }
    const { id: fileId } = await upload(params)

    if (!fileId) return
    const { data } = await uploadMandate({
      variables: {
        fileId: fileId,
        mandateId: mandate.id,
      },
    })

    if (!data?.insert_mandate_media_one) return

    const values: Partial<MandateFragment> = {
      status: Mandate_Status_Enum.Available,
    }
    if (date) values.mainSignDate = date.toISOString()

    await updateMandate({
      variables: {
        id: mandate.id,
        values,
      },
      onError: (error) => {
        console.error(error)
        toast({
          variant: 'destructive',
          title: t('MandateStatusCard.updateMandate.error.title'),
          description: t('MandateStatusCard.updateMandate.error.description'),
        })
      },
    })
  }

  useEffect(() => {
    if (isUploaded) {
      toast({
        variant: 'success',
        description: t('FileUpload.toast.success'),
      })
    }
  }, [isUploaded])

  useEffect(() => {
    if (isError) {
      toast({
        variant: 'destructive',
        description: t('FileUpload.toast.error'),
      })
    }
  }, [isError])

  const handleDownloadMandate = async () => {
    window.open(url, '_blank')
  }

  const handleGenerateMandate = async () => {
    if (!mandate?.id) return

    await createMandateRegistry({
      variables: {
        values: {
          mandateId: mandate.id,
          estateLabel: generateEstateLabel(estate),
          objectLabel: generateObjectLabel(mandate),
          representativesAddress:
            generateRepresentativesAddress(availableSellers),
          representativesNames: generateRepresentativesNames(availableSellers),
        },
      },
    })
  }

  const isFileUploadedStatus =
    mandate?.status === Mandate_Status_Enum.Available ||
    mandate?.status === Mandate_Status_Enum.Active // In the future, will represent signed mandate

  const isGenerated = useMemo(() => {
    return mandate?.mandate_registry != null
  }, [mandate])

  const canGenerateMandate = useMemo(() => {
    return !isGenerated && mandate?.type != null && isAgencyMember
  }, [mandate, isGenerated, isAgencyMember])

  return (
    <div className="tw-space-y-4">
      <p className="tw-text-2xl tw-font-semibold">
        {t('MandateStatusCard.heading')}
      </p>
      <Card>
        <CardContent className="tw-grid tw-gap-4 tw-pt-4">
          {(!mandate?.status ||
            mandate?.status === Mandate_Status_Enum.Draft) && (
            <>
              <div className="tw-py-3 tw-p-4  tw-bg-[#FFE8CC] tw-rounded-lg ">
                <p className="tw-text-sm tw-font-normal tw-text-[#D9841D] tw-flex tw-items-center">
                  <HourglassIcon
                    className="tw-h-4 tw-w-4 tw-mr-4"
                    strokeWidth={3}
                  />
                  {t(`MandateStatusCard.status.draft.text`)}
                </p>
              </div>
              <MandateUploadDialog
                onSubmit={handleMandateSubmit}
                isUploading={isUploading}
                progress={progress}
                button={
                  <Button
                    variant="green"
                    className="tw-justify-center"
                    disabled={!mandate}
                  >
                    {isUploading && progress ? (
                      <Loading active />
                    ) : (
                      t('MandateStatusCard.status.draft.button')
                    )}
                  </Button>
                }
              />
            </>
          )}

          {isFileUploadedStatus && (
            <>
              <div className="tw-py-3 tw-p-4 tw-bg-green-200 tw-rounded-lg ">
                <p className="tw-text-sm tw-font-normal tw-text-green-700 tw-flex tw-items-center">
                  <PencilIcon
                    className="tw-h-4 tw-w-4 tw-mr-4"
                    strokeWidth={3}
                  />
                  {t(`MandateStatusCard.status.available.text`)}
                </p>
              </div>
              <div className="tw-flex tw-justify-between tw-gap-3 tw-mt-4 tw-flex-wrap">
                <Button
                  variant="green"
                  className="tw-justify-center"
                  onClick={handleDownloadMandate}
                  disabled={!url}
                >
                  {t('MandateStatusCard.status.available.button.download')}
                </Button>
                {isAgencyMember && (
                  <MandateUploadDialog
                    onSubmit={handleMandateSubmit}
                    isUploading={isUploading}
                    progress={progress}
                    button={
                      <Button variant="secondary" className="tw-justify-center">
                        {isUploading && progress ? (
                          <Loading active />
                        ) : (
                          t('MandateStatusCard.status.available.button.upload')
                        )}
                      </Button>
                    }
                  />
                )}
              </div>
            </>
          )}

          <Button
            variant="secondary"
            onClick={() => setOpenConfirm(true)}
            disabled={!canGenerateMandate}
          >
            {t(`MandateStatusCard.generate`)}
          </Button>
          <ConfirmationDialog
            open={openConfirm}
            onOpenChange={() => setOpenConfirm(false)}
            description="Attention, une fois le n° de mandat généré, vous ne pourrez pas modifier le contenu de la page mandat, souhaitez-vous confirmer ?" // TODO translation
            onConfirm={handleGenerateMandate}
          />
        </CardContent>
      </Card>
    </div>
  )
}

export type MandateUploadDialogProps = {
  isUploading?: boolean
  progress?: number | null
  onSubmit?: (file: File, date: Date) => Promise<void>
  button: ReactNode
}
export const MandateUploadDialog = ({
  isUploading,
  progress,
  onSubmit,
  button,
}: MandateUploadDialogProps) => {
  const { t } = useTranslation()
  const [file, setFile] = useState<File>()
  const [date, setDate] = useState<Date>()
  const [isOpen, setIsOpen] = useState(false)

  const handleSave = () => {
    if (!file) return
    if (!date) return
    onSubmit?.(file, date)
    setIsOpen(false)
  }

  const handleDateChange = (date?: Date) => {
    setDate(date)
  }
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFile(event.target.files?.[0])
  }

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>{button}</DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t('MandateUploadDialog.title')}</DialogTitle>
          <DialogDescription>
            {t('MandateUploadDialog.description')}
          </DialogDescription>
        </DialogHeader>
        <div className="tw-grid tw-gap-4 tw-py-4">
          <div className="tw-grid tw-grid-cols-4 tw-items-center tw-gap-4">
            <Label htmlFor="date" className="text-right">
              {t('MandateUploadDialog.date')}
            </Label>
            <InputDatePicker
              id="date"
              className="tw-col-span-3"
              onChange={handleDateChange}
              fromYear={1900}
              toYear={2100}
            />
          </div>
          <div className="tw-grid tw-grid-cols-4 tw-items-center gap-4">
            <Label htmlFor="file-upload" className="tw-cursor-pointer">
              {isUploading && progress ? (
                <Loading active />
              ) : (
                t('MandateUploadDialog.file')
              )}
            </Label>
            <Input
              id="file-upload"
              type="file"
              className="tw-col-span-3"
              onChange={handleFileChange}
            />
          </div>
        </div>
        <DialogFooter>
          <Button type="button" onClick={handleSave} disabled={!file || !date}>
            {t('MandateUploadDialog.save')}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
