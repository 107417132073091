import { Progress } from '@/components/ui/progress'
import { EstateForSalePageFragment } from '@gql'
import { DEFAULT_DATE_FORMAT, parseDate } from '@almaris/shared/utils/dates'
import { format, isAfter, isBefore, isToday } from 'date-fns'
import { fr } from 'date-fns/locale'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

type ApplicationsTimelineProps = {
  estate: Pick<
    EstateForSalePageFragment,
    'id' | 'estateRounds' | 'currentRoundId' | 'visit_spans' | 'isSaleOpen'
  >
}

// If the sale
export const ApplicationsTimeline = ({ estate }: ApplicationsTimelineProps) => {
  const { t } = useTranslation()
  const { currentRoundId, visit_spans } = estate

  const currentRound = estate.estateRounds.find(
    (round) => round.id === currentRoundId
  )

  // We should always have a current round
  const { startDate, endDate } = currentRound!

  const startValue = useMemo(() => {
    if (estate.isSaleOpen) return 100

    if (startDate?.length > 0) return 100
    if (endDate?.length > 0) return 0
    return 0
  }, [startDate, endDate, estate])

  const openingSaleDate = useMemo(() => {
    return parseDate(startDate, DEFAULT_DATE_FORMAT) ?? new Date()
  }, [startDate])

  const closingSaleDate = useMemo(() => {
    return parseDate(endDate, DEFAULT_DATE_FORMAT)
  }, [endDate])

  const openingSaleValue = useMemo(() => {
    if (estate.isSaleOpen) return 100

    return isToday(openingSaleDate) || isBefore(openingSaleDate, new Date())
      ? 100
      : 0
  }, [openingSaleDate, estate])

  const sortedVisitSpans: Date[] = useMemo(() => {
    return visit_spans
      .flatMap((visit) => visit.span?.[0]?.startTime)
      .filter((startTime): startTime is Date => !!startTime) // filter empty strings
      .sort((a, b) => {
        const parsedA = parseDate(a, DEFAULT_DATE_FORMAT)
        const parsedB = parseDate(b, DEFAULT_DATE_FORMAT)
        if (!parsedA && !parsedB) return 0
        if (!parsedB) return 1
        if (!parsedA) return -1
        if (parsedA > parsedB) return 1
        if (parsedA < parsedB) return -1
        return 0
      })
  }, [visit_spans])

  const openingVisitsDate = useMemo(() => {
    return sortedVisitSpans.length > 0
      ? parseDate(sortedVisitSpans[0], DEFAULT_DATE_FORMAT)
      : null
  }, [sortedVisitSpans])

  const closingVisitsDate = useMemo(() => {
    return sortedVisitSpans.length > 0
      ? parseDate(
          sortedVisitSpans[sortedVisitSpans.length - 1],
          DEFAULT_DATE_FORMAT
        )
      : null
  }, [sortedVisitSpans])

  const visitsValue = useMemo(() => {
    if (!openingVisitsDate || !closingVisitsDate) return 0

    const today = new Date()
    if (isBefore(today, openingVisitsDate)) return 0
    if (isAfter(today, closingVisitsDate)) return 100
    return 50
  }, [openingVisitsDate, closingVisitsDate])

  const closingSaleValue = useMemo(() => {
    if (!closingSaleDate) return 0

    const today = new Date()
    if (isAfter(today, closingSaleDate)) return 100
    if (isBefore(today, closingSaleDate) && visitsValue === 100) return 50
    return 0
  }, [closingSaleDate, visitsValue])

  // TODO: Not sure what's the point of this value
  const endValue = useMemo(() => {
    return closingSaleValue === 100 ? 100 : 0
  }, [closingSaleValue])

  return (
    <div className="tw-flex tw-gap-2 tw-px-8 tw-mb-8 tw-w-full">
      <Progress
        className="tw-h-1 tw-w-[75px]"
        value={!estate.isSaleOpen ? 100 : startValue}
      />
      <div>
        <Progress
          className="tw-h-1 tw-w-44"
          value={!estate.isSaleOpen ? 100 : openingSaleValue}
        />
        <p className="tw-text-sm tw-text-zinc-500">
          {t('ApplicationsTimeline.openingSale')}
        </p>
        <p className="tw-text-sm tw-text-zinc-500">
          {format(openingSaleDate, 'dd MMMM yyyy', { locale: fr })}
        </p>
      </div>
      <div className="tw-grid tw-w-[80%]">
        <Progress
          className="tw-h-1"
          value={!estate.isSaleOpen ? 100 : visitsValue}
        />
        <div className="tw-flex tw-gap-1 tw-justify-between">
          <div>
            <p className="tw-text-sm tw-text-zinc-500">
              {t('ApplicationsTimeline.startVisits')}
            </p>
            {openingVisitsDate && (
              <p className="tw-text-sm tw-text-zinc-500">
                {format(openingVisitsDate, 'dd MMMM yyyy', { locale: fr })}
              </p>
            )}
          </div>
          <div>
            <p className="tw-text-sm tw-text-zinc-500">
              {t('ApplicationsTimeline.endVisits')}
            </p>
            {closingVisitsDate && (
              <p className="tw-text-sm tw-text-zinc-500">
                {format(closingVisitsDate, 'dd MMMM yyyy', { locale: fr })}
              </p>
            )}
          </div>
        </div>
      </div>
      <div className="tw-grid tw-w-full">
        <Progress
          className="tw-h-1 tw-w-full"
          value={!estate.isSaleOpen ? 100 : closingSaleValue}
        />
        <div className="tw-justify-self-end">
          <p className="tw-text-sm tw-text-zinc-500">
            {t('ApplicationsTimeline.closingSale')}
          </p>
          {closingSaleDate && (
            <p className="tw-text-sm tw-text-zinc-500">
              {format(closingSaleDate, 'dd MMMM yyyy', { locale: fr })}
            </p>
          )}
        </div>
      </div>
      <div className="tw-grid tw-w-[170px]">
        <Progress
          className="tw-h-1 tw-w-[170px]"
          value={!estate.isSaleOpen ? 100 : endValue}
        />
        <div className="tw-justify-self-end">
          <p className="tw-text-sm tw-text-zinc-500">
            {t('ApplicationsTimeline.endSale')}
          </p>
        </div>
      </div>
    </div>
  )
}
