import { Badge } from '@/components/ui/badge'
import { Application_Status_Enum, WebappApplicationFragment } from '@gql'
import React from 'react'
import { useTranslation } from 'react-i18next'

type ApplicationStatusBadgeProps = {
  application: WebappApplicationFragment
}

export const ApplicationStatusBadge = ({
  application,
}: ApplicationStatusBadgeProps) => {
  const { t } = useTranslation()

  const isSubmitted = application.status === Application_Status_Enum.Submitted
  const isAccepted = application.status === Application_Status_Enum.Accepted

  if (application.archived) {
    return (
      <Badge variant="destructive" className="tw-ml-2">
        {t('ApplicationsTableColumns.archived')}
      </Badge>
    )
  }

  if (!application.offerAmount) {
    return (
      <Badge className="tw-ml-2">{t(`common.applicationStatus.Waiting`)}</Badge>
    )
  }

  return (
    <Badge
      variant={isSubmitted || isAccepted ? 'success' : 'secondary'}
      className="tw-ml-2"
    >
      {t(`common.applicationStatus.${application.status}`)}
    </Badge>
  )
}
