import * as yup from 'yup'

// TODO handle spaces and special chars
export const isPhoneNumber = (value?: string) => {
  if (!value) return false
  return value.match(/^[0-9]{10}$/) != null
}

export const isEmpty = (value?: string) => {
  if (!value) return true
  return value.length === 0
}

export const isPhoneNumberOrEmpty = (value?: string) => {
  return isPhoneNumber(value) || isEmpty(value)
}

export const nameSchema = yup.string().min(1, {
  key: 'Errors.stringTooShort',
  values: { min: 1 },
})

export const emailSchema = yup.string().email({
  key: 'Errors.email',
})

export const slugSchema = yup
  .string()
  .min(5)
  .max(30)
  .test(
    'Slug',
    'Slug must be lowercase and contain only letters, numbers and dashes',
    (slug = '') => /^[a-z0-9]+(-[a-z0-9]+)*$/.test(slug)
  )

export const addressSchema = yup.object().shape({
  city: yup.string().nullable().default(null),
  country: yup.string().nullable().default(null),
  line1: yup.string().nullable().default(null),
  line2: yup.string().nullable().default(null),
  postal_code: yup.string().nullable().default(null),
  state: yup.string().nullable().default(null),
})

export const billingDetailsSchema = yup.object().shape({
  name: yup.string().nullable(),
  email: yup.string().nullable(),
  address: addressSchema.nullable().optional(),
})

export const passwordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/
// TODO rename to getPasswordSchema
// TODO use options prop
// TODO use yup.addMethod
export const passwordSchema = (message: string) =>
  yup.string().matches(passwordRegex, {
    message,
  })

// TODO use yup.addMethod
export type GetDateStringSchemaProps = {
  optional?: boolean
}
export const getDateStringSchema = (options: GetDateStringSchemaProps) =>
  yup.string().test('Date', 'Invalid date', (value?: string) => {
    if (!value) return options.optional
    return !isNaN(new Date(value).getTime())
  })

export const nullableNumberSchema = yup
  .number()
  .nullable()
  .transform((_, value) => {
    return value === '' ? null : !value ? null : Number(value)
  })
