import { Loading } from '@/common/atoms/Loading'
import TextError from '@/common/atoms/TextError'
import { Card } from '@/components/ui/card'
import { useGetEstateForSellersPageQuery, useGetSellersQuery } from '@gql'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { EstateSellerForm } from '../components/Estate/EstateSellerForm'
import { EstateNotFoundError } from '../utils/EstateNotFoundError'
import * as Apollo from '@apollo/client'

export const EstateSellersPage = () => {
  const { t } = useTranslation()
  const { estateId } = useParams<{ estateId: string }>()

  Apollo.useQuery

  const estateQuery = useGetEstateForSellersPageQuery({
    variables: {
      id: estateId!,
    },
    skip: estateId == null,
    fetchPolicy: 'cache-and-network',
  })
  const estate = useMemo(
    () => estateQuery.data?.estate_by_pk,
    [estateQuery.data]
  )
  const estateSellers = useMemo(
    () => estateQuery.data?.estate_seller,
    [estateQuery.data]
  )

  const isMandateGenerated = useMemo(() => {
    return estate?.mandate?.mandate_registry == null
  }, [estate?.mandate?.mandate_registry])

  const sellersQuery = useGetSellersQuery({
    fetchPolicy: 'cache-and-network',
  })

  const otherSellers = useMemo(() => {
    if (estate == null) return null

    return (
      sellersQuery.data?.seller.filter(
        (s) => !s.archived && s.id !== estate.sellerId
      ) ?? []
    )
  }, [sellersQuery.data?.seller, estate])

  return (
    <div className="tw-px-8 tw-py-6">
      <h1 className="tw-text-[40px] tw-font-semibold">
        {t('EstateSellers.title')}
      </h1>
      <div className="tw-w-[650px] tw-py-4 md:tw-py-6">
        {estateQuery.loading || sellersQuery.loading ? (
          <Loading active />
        ) : estateQuery.error ? (
          <TextError error={estateQuery.error} />
        ) : sellersQuery.error ? (
          <TextError error={sellersQuery.error} />
        ) : estate == null || estateSellers == null || otherSellers == null ? (
          <TextError error={new EstateNotFoundError(t)} />
        ) : (
          <Card className="tw-p-4">
            <EstateSellerForm
              disabled={!isMandateGenerated}
              estate={estate}
              estateSellers={estateSellers}
              otherSellers={otherSellers}
            />
          </Card>
        )}
      </div>
    </div>
  )
}
