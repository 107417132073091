import {
  AddressCombobox,
  AddressComboboxModeEnum,
} from '@/api-adresse/AddressCombobox'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import {
  EstateIdentityAndAdressSchema,
  useEstateIdentityAndAdressSchema,
} from '@/estate/hooks/useEstateIdentityAndAdressSchema'
import { EstateForMainPageFragment } from '@gql'
import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect } from 'react'
import { FieldErrors, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

export type EstateIdentityAndAddressFormProps = {
  estate: Pick<
    EstateForMainPageFragment,
    'id' | 'name' | 'address' | 'zipCode' | 'city'
  >
  onSubmit: (values: EstateIdentityAndAdressSchema) => void
  isMandateGenerated: boolean
}
export const EstateIdentityAndAddressForm = ({
  estate,
  onSubmit,
  isMandateGenerated,
}: EstateIdentityAndAddressFormProps) => {
  const { t } = useTranslation()

  const { schema } = useEstateIdentityAndAdressSchema()

  const defaultValues: EstateIdentityAndAdressSchema = {
    name: estate.name ?? '',
    address: estate.address ?? '',
    city: estate.city ?? '',
    zipCode: estate.zipCode ?? '',
  }

  const form = useForm<EstateIdentityAndAdressSchema>({
    resolver: yupResolver(schema),
    defaultValues,
  })

  const onInvalid = (errors: FieldErrors<EstateIdentityAndAdressSchema>) => {
    console.log({ errors })
  }

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout> | null = null
    const { unsubscribe } = form.watch(() => {
      if (timeoutId != null) {
        clearTimeout(timeoutId)
        timeoutId = null
      }
      timeoutId = setTimeout(() => {
        form.handleSubmit(onSubmit, onInvalid)()
      }, 1000)
    })
    return () => {
      if (timeoutId != null) clearTimeout(timeoutId)
      unsubscribe()
    }
  }, [form, form.watch, onSubmit])

  return (
    <Form {...form}>
      <div className="tw-grid tw-grid-cols-2 tw-gap-4">
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('EstateIdentityAndAddressForm.name')}</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="address"
          render={({ field }) => {
            return (
              <FormItem>
                <FormLabel>
                  {t('EstateIdentityAndAddressForm.address')} *
                </FormLabel>
                <FormControl>
                  <AddressCombobox
                    disabled={isMandateGenerated}
                    {...field}
                    onSelectItem={({
                      addressLabel,
                      postalCode,
                      city,
                      country,
                    }) => {
                      form.setValue('address', addressLabel)
                      form.setValue('zipCode', postalCode ?? '')
                      form.setValue('city', city)
                      // TODO add country
                      // form.setValue('country', country)
                    }}
                    mode={AddressComboboxModeEnum.ADDRESS}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )
          }}
        />
        <FormField
          control={form.control}
          name="zipCode"
          render={({ field }) => {
            return (
              <FormItem>
                <FormLabel>
                  {t('EstateIdentityAndAddressForm.zipCode')} *
                </FormLabel>
                <FormControl>
                  <AddressCombobox
                    disabled={isMandateGenerated}
                    {...field}
                    onSelectItem={({ postalCode, city, country }) => {
                      form.setValue('zipCode', postalCode ?? '')
                      form.setValue('city', city)
                      // TODO add country
                      // form.setValue('country', country)
                    }}
                    mode={AddressComboboxModeEnum.ZIP_CODE}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )
          }}
        />
        <FormField
          control={form.control}
          name="city"
          render={({ field }) => {
            return (
              <FormItem>
                <FormLabel>
                  {t('EstateIdentityAndAddressForm.city')} *
                </FormLabel>
                <FormControl>
                  <AddressCombobox
                    disabled={isMandateGenerated}
                    {...field}
                    onSelectItem={({ city, country }) => {
                      form.setValue('city', city)
                      // TODO add country
                      // form.setValue('country', country)
                    }}
                    mode={AddressComboboxModeEnum.CITY}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )
          }}
        />
      </div>
    </Form>
  )
}
