import { formatValue, CurrencyInputProps } from 'react-currency-input-field'

export const formatCurrency = (value: number) => {
  return formatValue({
    ...currencyFieldDefaultProps,
    value: value.toString(),
  })
}

export const currencyFieldDefaultProps: CurrencyInputProps = {
  suffix: '€',
  groupSeparator: ' ',
  step: 1,
}
