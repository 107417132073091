import { Button } from '@/components/ui/button'
import {
  Form,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { InputDatePicker } from '@/components/ui/InputDatePicker'
import { TimePicker } from '@/components/ui/timePicker'
import { useToast } from '@/components/ui/use-toast'
import {
  EstateSettingOffersFormValues,
  useEstateSettingOffersSchema,
} from '@/estate/hooks/useEstateSettingOffersSchema'
import { EstateForSalePageFragment } from '@gql'
import { yupResolver } from '@hookform/resolvers/yup'
import { DEFAULT_DATE_FORMAT, parseDate } from '@almaris/shared/utils/dates'
import { format } from 'date-fns'
import { RefreshCcwDotIcon } from 'lucide-react'
import React, { useMemo, useState } from 'react'
import { FieldErrors, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { trpc } from 'src/trpc'
import { EstateBuyerMemberMultiSelect } from '../EstateBuyerMember/EstateBuyerMemberMultiSelect'

type EstateSettingOffersFormProps = {
  estate: Pick<EstateForSalePageFragment, 'id' | 'isPublished' | 'estateRounds'>
  readOnly?: boolean
}

export const EstateSettingOffersForm = ({
  estate,
  readOnly,
}: EstateSettingOffersFormProps) => {
  const { t } = useTranslation()
  const { toast } = useToast()

  const { schema } = useEstateSettingOffersSchema()

  const estateRound = estate.estateRounds[0] ?? {}
  const endDate = useMemo(
    () => parseDate(estateRound.endDate, DEFAULT_DATE_FORMAT),
    [estateRound.endDate]
  )

  const isEstateRoundFinished = useMemo(() => {
    const now = new Date()
    return endDate && endDate.getTime() < now.getTime()
  }, [endDate])

  const [showForm, setShowForm] = useState(isEstateRoundFinished)

  const endTime = endDate && format(endDate, 'HH:mm')

  const form = useForm<EstateSettingOffersFormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      endDate,
      endTime,
      buyerMemberIds:
        estateRound.estateRoundBuyerMembers?.map(
          ({ buyerMemberId }) => buyerMemberId
        ) ?? [],
    },
  })
  const onSubmit = async ({
    endDate,
    endTime,
    buyerMemberIds,
  }: EstateSettingOffersFormValues) => {
    if (!estate.isPublished) return
    if (!isEstateRoundFinished) return
    const estateId = estate.id

    const [endHour, endMinute] = endTime.split(':').map(Number)
    endDate.setHours(endHour, endMinute, 0, 0)
    try {
      await trpc.estate.newRound.mutate({
        estateId,
        endDate,
        buyerMemberIds,
      })
      toast({
        title: t(
          'EstateSettingOffersForm.newRound.toast.success.title'
        ) as string,
        description: t(
          'EstateSettingOffersForm.newRound.toast.success.description'
        ) as string,
        variant: 'success',
      })
    } catch (error) {
      console.error(error)
      toast({
        title: t(
          'EstateSettingOffersForm.newRound.toast.error.title'
        ) as string,
        description: t(
          'EstateSettingOffersForm.newRound.toast.error.description'
        ) as string,
        variant: 'destructive',
      })
    }
  }

  const onInvalid = (errors: FieldErrors<EstateSettingOffersFormValues>) => {
    console.log({ errors })
  }
  const hasDirtyFields = Object.keys(form.formState.dirtyFields).length > 0

  return (
    <>
      <div className="tw-mt-3 tw-bg-zinc-50 tw-text-zinc-500 tw-flex tw-items-start tw-p-5 tw-justify-between tw-gap-3">
        <div>
          <RefreshCcwDotIcon />
        </div>
        <div className="tw-flex tw-flex-col tw-gap-2">
          <div>
            <p className="tw-font-medium">
              {t('EstateSettingOffersForm.infoCard.title')}
            </p>
            <p className="tw-text-xs">
              {t('EstateSettingOffersForm.infoCard.subTitle')}
            </p>
          </div>
          <div>
            <p className="tw-text-sm">
              {t('EstateSettingOffersForm.infoCard.description')}
            </p>
          </div>
        </div>
        <Button
          disabled={!estate.isPublished || !isEstateRoundFinished || readOnly}
          className="tw-self-end"
          size="sm"
          onClick={() => setShowForm((prev) => !prev)}
        >
          {t('EstateSettingOffersForm.infoCard.button')}
        </Button>
      </div>
      {showForm && (
        <Form {...form}>
          <form
            className="tw-space-y-6 tw-pt-6 tw-h-full"
            onSubmit={form.handleSubmit(onSubmit, onInvalid)}
          >
            <div className="tw-flex tw-space-x-4">
              <FormField
                control={form.control}
                name="endDate"
                render={({ field }) => (
                  <FormItem className="tw-flex tw-flex-col tw-w-full">
                    <FormLabel>
                      {t('EstateSettingOffersForm.form.endDate.date')}
                    </FormLabel>
                    <InputDatePicker
                      {...field}
                      placeholder={t(
                        'EstateSettingOffersForm.form.endDate.placeholder'
                      )}
                      fromDate={endDate}
                      disabled={readOnly}
                    />
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="endTime"
                render={({ field }) => (
                  <FormItem className="tw-flex tw-flex-col tw-w-full">
                    <FormLabel>
                      {t('EstateSettingOffersForm.form.endDate.hour')}
                    </FormLabel>
                    {/* TODO make a component with InputDatePicker that works with dates */}
                    <TimePicker
                      {...field}
                      className="tw-w-full"
                      disabled={readOnly}
                    />
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <FormField
              name="buyerMemberIds"
              render={({ field }) => (
                <EstateBuyerMemberMultiSelect
                  estateId={estate.id}
                  defaultValue={field.value ?? []}
                  onValueChange={field.onChange}
                  disabled={readOnly}
                  {...field}
                />
              )}
            />
            {!readOnly && (
              <Button
                variant="secondary"
                type="submit"
                disabled={!hasDirtyFields}
              >
                {t('EstateSettingOffersForm.form.submit')}
              </Button>
            )}
          </form>
        </Form>
      )}
    </>
  )
}
