import React from 'react'
import { EstateForMainPageFragment } from '@gql'
import {
  GenericForm,
  GenericFormConfig,
  genericFormMultiSelectEnumFieldParams,
  genericFormSelectEnumFieldParams,
} from '../../../form/GenericForm'
import { useTranslation } from 'react-i18next'
import { Textarea } from '@/components/ui/textarea'
import { OccupationTypeSelect } from '@/common/atoms/OccupationTypeSelect'
import { EquipmentMultiSelect } from '@/common/atoms/EquipmentMultiSelect'
import { AreaDemandLevelSelect } from '@/common/atoms/AreaDemandLevelSelect'
import { UrbanPreemptionRightTypeSelect } from '@/common/atoms/UrbanPreemptionRightTypeSelect'
import { PrimaryPurposeSelect } from '@/common/atoms/PrimaryPurposeSelect'
import { OrientationMultiSelect } from '@/common/atoms/OrientationMultiSelect'

export type EstateFeaturesFormProps = {
  estate: Pick<EstateForMainPageFragment, 'id' | 'features'>
  onSubmit: (values: Partial<EstateForMainPageFragment>) => void
  disabled?: boolean
}
export const EstateFeaturesForm = ({
  estate,
  onSubmit,
  disabled = false,
}: EstateFeaturesFormProps) => {
  const { t } = useTranslation()

  const config: GenericFormConfig = [
    {
      name: 'building',
      containerProps: { className: 'tw-col-span-6' },
    },
    {
      name: 'propertyFloor',
      type: 'nullableNonNegativeNumber',
    },
    {
      name: 'door',
    },
    {
      name: 'numberOfFloors',
      type: 'nullableNonNegativeNumber',
    },
    {
      name: 'constructionYear',
      type: 'nullableNonNegativeNumber',
    },
    {
      name: 'carrezLawSurface',
      type: 'nullableNonNegativeNumber',
    },
    {
      name: 'habitableSurface',
      type: 'nullableNonNegativeNumber',
    },
    {
      name: 'propertyDescription',
      FieldComponent: Textarea,
      containerProps: { className: 'tw-col-span-6' },
    },
    {
      name: 'marketingDescription',
      FieldComponent: Textarea,
      containerProps: { className: 'tw-col-span-6' },
    },
    {
      name: 'primaryPurpose',
      FieldComponent: PrimaryPurposeSelect,
      ...genericFormSelectEnumFieldParams,
    },
    {
      name: 'landSurface',
      type: 'nullableNonNegativeNumber',
      condition: (formValues, { estate }) =>
        estate.type === 'House' || estate.type === 'Land',
    },
    {
      name: 'balconyTerraceSurface',
      type: 'nullableNonNegativeNumber',
    },
    {
      name: 'gardenSurface',
      type: 'nullableNonNegativeNumber',
      condition: (formValues, { estate }) => estate.type === 'Apartment',
    },
    {
      name: 'furnished',
      type: 'boolean',
      containerProps: { className: 'tw-col-start-1 tw-col-span-2' },
    },
    {
      name: 'cellar',
      type: 'boolean',
      containerProps: { className: 'tw-col-span-2' },
    },
    {
      name: 'pool',
      type: 'boolean',
      containerProps: { className: 'tw-col-span-2' },
    },
    {
      name: 'numberOfRooms',
      type: 'nullableNonNegativeNumber',
    },
    {
      name: 'numberOfBedrooms',
      type: 'nullableNonNegativeNumber',
    },
    {
      name: 'numberOfBathrooms',
      type: 'nullableNonNegativeNumber',
    },
    {
      name: 'numberOfGarages',
      type: 'nullableNonNegativeNumber',
    },
    {
      name: 'numberOfParkings',
      type: 'nullableNonNegativeNumber',
    },
    {
      name: 'orientation',
      FieldComponent: OrientationMultiSelect,
      ...genericFormMultiSelectEnumFieldParams,
    },
    {
      name: 'equipments',
      FieldComponent: EquipmentMultiSelect,
      ...genericFormMultiSelectEnumFieldParams,
      containerProps: { className: 'tw-col-span-6' },
    },
    {
      name: 'inDemandingArea',
      type: 'boolean',
    },
    {
      name: 'areaDemandLevel',
      condition: ({ inDemandingArea }) => inDemandingArea,
      FieldComponent: AreaDemandLevelSelect,
      ...genericFormSelectEnumFieldParams,
    },
    {
      name: 'propertyFreeOfOccupation',
      type: 'boolean',
    },
    {
      name: 'occupationType',
      condition: ({ propertyFreeOfOccupation }) => !propertyFreeOfOccupation,
      FieldComponent: OccupationTypeSelect,
      ...genericFormSelectEnumFieldParams,
    },
    {
      name: 'accessDetails',
    },
    {
      name: 'propertyTaxAmount',
      type: 'nullableNonNegativeNumber',
    },
    {
      name: 'housingTaxAmount',
      type: 'nullableNonNegativeNumber',
    },
    {
      name: 'annualChargesAmount',
      type: 'nullableNonNegativeNumber',
    },
    {
      name: 'urbanPreemptionRightType',
      FieldComponent: UrbanPreemptionRightTypeSelect,
      ...genericFormSelectEnumFieldParams,
    },
    {
      name: 'dpuClearanceTime',
      type: 'nullableNonNegativeNumber',
    },
  ]

  const handleSubmit = (
    features: Partial<EstateForMainPageFragment['features']>
  ) => {
    onSubmit({ features })
  }

  return (
    <GenericForm
      t={t}
      t_key="EstateFeaturesForm"
      config={config}
      values={estate.features}
      context={{ estate }}
      onSubmit={handleSubmit}
      disabled={disabled}
    />
  )
}
