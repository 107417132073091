import { EstateForMandatePageFragment, MandateFragment } from '@gql'
import i18next from 'i18next'

export function generateRepresentativesNames(
  availableSellers: {
    id: string
    name: string
    headquarter?: string | null
    address?: string | null
  }[]
): string {
  return availableSellers
    .map(
      ({ name }, index) =>
        `${index + 1}. ${name ?? i18next.t('MandateInfoForm.defaultValues.representatives.emptyValue')}`
    )
    .join('\n')
}

export function generateRepresentativesAddress(
  availableSellers: {
    headquarter?: string | null
    address?: string | null
  }[]
): string {
  return availableSellers
    .map(
      ({ headquarter, address }, index) =>
        `${index + 1}. ${headquarter ? headquarter : address ? address : i18next.t('MandateInfoForm.defaultValues.representatives.emptyValue')}`
    )
    .join('\n')
}

export function generateObjectLabel(
  mandate: MandateFragment | null | undefined
) {
  return mandate?.type ? i18next.t(`common.mandate.${mandate?.type}`) : ''
}

export function generateEstateLabel(
  estate: Pick<
    EstateForMandatePageFragment,
    'address' | 'type' | 'zipCode' | 'city' | 'seller' | 'id' | 'estate_sellers'
  >
) {
  return i18next.t('MandateInfoForm.defaultValues.estateLabel', {
    type: estate.type ? i18next.t(`common.estate.${estate.type}`) : '',
    address: estate.address,
    zipCode: estate.zipCode,
    city: estate.city,
  })
}
