import {
  Form,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { InputDatePicker } from '@/components/ui/InputDatePicker'
import { TimePicker } from '@/components/ui/timePicker'
import { useToast } from '@/components/ui/use-toast'
import {
  EstateSettingOpeningFormDatesValues,
  useEstateSettingOpeningFormDatesSchema,
} from '@/estate/hooks/useEstateSettingOpeningFormDates'
import { EstateForSalePageFragment, useUpdateEstateRoundMutation } from '@gql'
import { yupResolver } from '@hookform/resolvers/yup'
import { DEFAULT_DATE_FORMAT, parseDate } from '@almaris/shared/utils/dates'
import { format } from 'date-fns'
import React, { useEffect, useMemo } from 'react'
import { FieldErrors, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { trpc } from 'src/trpc'

type EstateSettingOpeningFormDatesProps = {
  estate: Pick<EstateForSalePageFragment, 'id' | 'estateRounds'>
  readOnly?: boolean
}

export const EstateSettingOpeningFormDates = ({
  estate,
  readOnly,
}: EstateSettingOpeningFormDatesProps) => {
  const { t } = useTranslation()
  const { toast } = useToast()

  const [updateEstateRound] = useUpdateEstateRoundMutation({
    refetchQueries: ['getEstates'],
  })

  const { schema } = useEstateSettingOpeningFormDatesSchema()

  const estateRound = useMemo(() => {
    return estate.estateRounds[0] ?? {}
  }, [estate])

  const startDate = useMemo(() => {
    const defaultStartDate = new Date()
    defaultStartDate.setHours(9, 0, 0, 0)
    return (
      parseDate(estateRound.startDate, DEFAULT_DATE_FORMAT) ?? defaultStartDate
    )
  }, [estateRound])

  const endDate = useMemo(() => {
    const defaultEndDate = new Date()
    defaultEndDate.setHours(18, 0, 0, 0)
    defaultEndDate.setMonth(defaultEndDate.getMonth() + 1)
    return parseDate(estateRound.endDate, DEFAULT_DATE_FORMAT) ?? defaultEndDate
  }, [estateRound])

  const disableStartDate = useMemo(() => {
    return estateRound.number > 1 && startDate.getTime() < new Date().getTime()
  }, [estateRound, startDate])

  const defaultValues = useMemo(() => {
    return {
      startDate,
      endDate,
      startTime: format(startDate, 'HH:mm'),
      endTime: format(endDate, 'HH:mm'),
    }
  }, [endDate, startDate])

  const form = useForm<EstateSettingOpeningFormDatesValues>({
    resolver: yupResolver(schema),
    defaultValues,
  })

  const onSubmit = async ({
    startTime,
    startDate,
    endTime,
    endDate,
  }: EstateSettingOpeningFormDatesValues) => {
    const [startHour, startMinute] = startTime.split(':').map(Number)
    startDate.setHours(startHour, startMinute, 0, 0)

    const [endHour, endMinute] = endTime.split(':').map(Number)
    endDate.setHours(endHour, endMinute, 0, 0)

    if (estateRound) {
      await updateEstateRound({
        variables: {
          id: estateRound.id,
          values: {
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString(),
          },
        },
        onCompleted: () => {
          toast({
            variant: 'success',
            description: t('EstateSettingOpeningFormDates.toast.success'),
          })
        },
        onError: (error) => {
          console.error(error)
          toast({
            variant: 'destructive',
            title: t(
              'EstateSettingOpeningFormDates.updateEstateRound.error.title'
            ),
            description: t(
              'EstateSettingOpeningFormDates.updateEstateRound.error.description'
            ),
          })
        },
      })
    } else {
      try {
        await trpc.estate.newRound.mutate({
          estateId: estate.id,
          endDate,
        })
        toast({
          title: t(
            'EstateSettingOffersForm.newRound.toast.success.title'
          ) as string,
          description: t(
            'EstateSettingOffersForm.newRound.toast.success.description'
          ) as string,
          variant: 'success',
        })
      } catch (error) {
        console.error(error)
        toast({
          title: t(
            'EstateSettingOffersForm.newRound.toast.error.title'
          ) as string,
          description: t(
            'EstateSettingOffersForm.newRound.toast.error.description'
          ) as string,
          variant: 'destructive',
        })
      }
    }
  }

  const onInvalid = (
    errors: FieldErrors<EstateSettingOpeningFormDatesValues>
  ) => {
    console.log({ errors })
  }

  useEffect(() => {
    const { unsubscribe } = form.watch((value, { type }) => {
      if (type !== 'change') return
      form.handleSubmit(onSubmit, onInvalid)()
    })
    return () => unsubscribe()
  }, [form.watch])

  return (
    <Form {...form}>
      <form className="tw-space-y-6 tw-pt-6">
        <div className="tw-flex tw-space-x-4 ">
          <FormField
            control={form.control}
            name="startDate"
            render={({ field }) => (
              <FormItem className="tw-flex tw-flex-col tw-w-full">
                <FormLabel>
                  {t('EstateSettingOpeningFormDates.startDate.date')}
                </FormLabel>
                <InputDatePicker
                  {...field}
                  fromYear={1900}
                  toYear={2100}
                  disabled={readOnly || disableStartDate}
                />
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="startTime"
            render={({ field }) => (
              <FormItem className="tw-flex tw-flex-col tw-w-full">
                <FormLabel>
                  {t('EstateSettingOpeningFormDates.startDate.hour')}
                </FormLabel>
                {/* TODO make a component with InputDatePicker that works with dates */}
                <TimePicker
                  {...field}
                  disabled={readOnly || disableStartDate}
                />
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className="tw-flex tw-space-x-4">
          <FormField
            control={form.control}
            name="endDate"
            render={({ field }) => (
              <FormItem className="tw-flex tw-flex-col tw-w-full">
                <FormLabel>
                  {t('EstateSettingOpeningFormDates.endDate.date')}
                </FormLabel>
                <InputDatePicker
                  {...field}
                  fromYear={1900}
                  toYear={2100}
                  disabled={readOnly}
                />
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="endTime"
            render={({ field }) => (
              <FormItem className="tw-flex tw-flex-col tw-w-full">
                <FormLabel>
                  {t('EstateSettingOpeningFormDates.endDate.hour')}
                </FormLabel>
                {/* TODO make a component with InputDatePicker that works with dates */}
                <TimePicker {...field} disabled={readOnly} />
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
      </form>
    </Form>
  )
}
