import React from 'react'
import { Button } from '@/components/ui/button'
import { CellContext, ColumnDef } from '@tanstack/react-table'
import { ChevronsUpDownIcon } from 'lucide-react'

import { Link } from 'react-router-dom'
import { EstatesTableActionDropdown } from './EstatesTableActionDropdown'
import { TFunction } from 'react-i18next'
import {
  Application_Status_Enum,
  Estate_Media_Type_Group_Enum,
  WebappSimpleEstateFragment,
  SellerFragment,
} from '@gql'
import { useGetPresignedUrl } from '@/file/hooks/useGetPresignedUrl'
import { Skeleton } from '@/components/ui/skeleton'
import HouseSvg from 'src/images/house.svg'
import {
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip'
import {
  EstateStatusBadge,
  getEstateStatusKey,
} from '../EstateStatusBadge/EstateStatusBadge'
import { getEstateStats } from '@/estate/utils/getEstateStats'
import { useEstateMedias } from '@/estateMedia/hooks/useEstateMedias'
import { formatDate, TABLE_DATE_FORMAT } from '@almaris/shared/utils/dates'
import { formatCurrency } from '@almaris/shared/utils/formatCurrency'

export type TableEstateFragment = WebappSimpleEstateFragment & {
  applications: { status: Application_Status_Enum }[]
  visit_spans: { visit_slots: { isVisitDone: boolean }[] }[]
}

const EstateMediaEmptyState = () => {
  return (
    <div className="tw-flex tw-items-center tw-justify-center tw-h-10 tw-w-10 tw-rounded-full tw-text-zinc-500 tw-bg-zinc-100">
      <HouseSvg className="tw-h-6 tw-w-6" />
    </div>
  )
}

const EstateMediaPreview = ({ estateId }: { estateId: string }) => {
  const { estateMedias } = useEstateMedias({
    estateId,
    typeGroup: Estate_Media_Type_Group_Enum.Media,
  })

  const fileId = estateMedias[0]?.fileId

  const { url, loading } = useGetPresignedUrl({
    fileId: fileId!,
    skip: !fileId,
  })

  if (!fileId) return <EstateMediaEmptyState />
  if (loading) return <Skeleton className="tw-size-full" />
  if (!url) return <EstateMediaEmptyState />
  return <img src={url} alt="Estate picture" className="tw-size-full" />
}

const nameCell = ({ row }: CellContext<TableEstateFragment, any>) => {
  const estate = row.original
  const estateId = estate.id

  return (
    <Link
      key={estateId}
      to={`estates/${estateId}`}
      className="tw-font-semibold"
    >
      <div className="tw-flex tw-items-center tw-space-x-4">
        <div className="tw-shrink-0 tw-grow-0 tw-h-10 tw-w-10 tw-rounded-full tw-overflow-hidden ">
          <EstateMediaPreview estateId={estateId} />
        </div>
        <div>
          <p className="tw-text-sm tw-font-medium">{estate.name}</p>
          <p className="tw-text-sm tw-text-muted-foreground">
            {estate.address} {estate.zipCode} {estate.city}
          </p>
        </div>
      </div>
    </Link>
  )
}

export const columns = (
  t: TFunction
): ColumnDef<TableEstateFragment, any>[] => [
  {
    accessorKey: 'name',
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          size="sm"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          {t('EstatesTableColumns.name')}
          <ChevronsUpDownIcon className="tw-ml-2 tw-h-4 tw-w-4" />
        </Button>
      )
    },
    cell: nameCell,
    enableGrouping: true,
    getGroupingValue: (row) => `${row.address}, ${row.zipCode}, ${row.city}`,
  },
  {
    id: 'seller',
    accessorKey: 'seller',
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          {t('EstatesTableColumns.seller')}
          <ChevronsUpDownIcon className="tw-ml-2 tw-h-4 tw-w-4" />
        </Button>
      )
    },
    cell: ({ row }) => {
      const info = row.original
      return <span>{info.seller.name}</span>
    },
    accessorFn: (row) => row.seller,
    sortingFn: (rowA, rowB) => {
      const sellerA = rowA.original.seller
      const sellerB = rowB.original.seller
      return sellerA.name.localeCompare(sellerB.name)
    },
    filterFn: (row, columnId, filterValue: string[]) => {
      const seller = row.getValue<SellerFragment>(columnId)
      return filterValue.includes(seller.id)
    },
    enableGrouping: true,
    getGroupingValue: ({ seller }) => `${seller.name}`,
  },
  {
    accessorKey: 'status',
    header: t('EstatesTableColumns.status'),
    cell: ({ row }) => {
      const estate = row.original

      return <EstateStatusBadge estate={estate} />
    },
    getGroupingValue: (estate) => getEstateStatusKey(estate),
  },
  {
    accessorKey: 'endOfSale',
    header: t('EstatesTableColumns.endOfSale'),
    cell: ({ row }) => {
      const estate = row.original

      const currentRound = estate.estateRounds.find(
        (round) => round.id === estate.currentRoundId
      )

      let endDate = estate.isSaleOpen ? currentRound?.endDate : null
      endDate = estate.applicationAcceptedDate ?? endDate

      if (!endDate)
        return (
          <p className="tw-text-zinc-400">
            {t('EstatesTableColumns.noEndOfSale')}
          </p>
        )
      return (
        <div>
          <p>{formatDate(endDate, { format: TABLE_DATE_FORMAT })}</p>
          <p className="tw-text-zinc-500">
            {formatDate(endDate, { format: 'p' })}
          </p>
        </div>
      )
    },
    enableGrouping: true,
    getGroupingValue: (estate) => {
      const currentRound = estate.estateRounds.find(
        (round) => round.id === estate.currentRoundId
      )
      const endDate = estate.isSaleOpen ? currentRound?.endDate : null

      return formatDate(endDate, {
        format: 'Pp',
      })
    },
  },
  {
    accessorKey: 'price',
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          {t('EstatesTableColumns.price')}
          <ChevronsUpDownIcon className="tw-ml-2 tw-h-4 tw-w-4" />
        </Button>
      )
    },
    cell: ({ row }) => {
      const estate = row.original
      if (!estate.startEstimate || !estate.endEstimate) return
      return (
        <span>
          {formatCurrency(estate.startEstimate)}
          <br />
          {formatCurrency(estate.endEstimate)}
        </span>
      )
    },
    sortingFn: (rowA, rowB) => {
      if (!rowA.original.endEstimate || !rowB.original.endEstimate) return 0
      const priceA: number = rowA.original.endEstimate
      const priceB: number = rowB.original.endEstimate

      return priceA - priceB
    },
    enableGrouping: true,
    getGroupingValue: ({ startEstimate, endEstimate }) =>
      `${startEstimate} ${endEstimate}`,
  },

  {
    accessorKey: 'visits',
    header: t('EstatesTableColumns.visits'),
    cell: ({ row }) => {
      const applications = row.original.applications || []

      const plannedVisitsLength = applications.filter(
        (application) => application.status === Application_Status_Enum.Draft
      ).length

      const doneVisitsLength = applications.filter(
        (application) =>
          application.status === Application_Status_Enum.Cancelled // TODO get visit slot status
      ).length

      return (
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger>
              <p>
                {doneVisitsLength}/{doneVisitsLength + plannedVisitsLength}
              </p>
            </TooltipTrigger>
            <TooltipContent>
              <TooltipArrow />
              <p>
                {t('EstatesTableColumns.plannedVisits', {
                  count: plannedVisitsLength,
                })}
              </p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      )
    },
  },
  {
    accessorKey: 'offers',
    header: t('EstatesTableColumns.offers'),
    cell: ({ row }) => {
      // TODO: fix this!
      const { nbInProgressApplications, nbReceivedApplications } =
        getEstateStats(row.original)

      return (
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger>
              <p>
                {nbReceivedApplications}/
                {nbInProgressApplications + nbReceivedApplications}
              </p>
            </TooltipTrigger>
            <TooltipContent>
              <TooltipArrow />
              <p>
                {t('EstatesTableColumns.receivedApplications', {
                  count: nbReceivedApplications,
                })}
              </p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      )
    },
  },
  {
    accessorKey: 'mission_head',
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          {t('EstatesTableColumns.missionHead')}
          <ChevronsUpDownIcon className="tw-ml-2 tw-h-4 tw-w-4" />
        </Button>
      )
    },
    cell: ({ row }) => {
      const estate = row.original
      return <span>{estate.mission_head?.fullName}</span>
    },
    enableGrouping: true,
    getGroupingValue: ({ missionHeadId }) => missionHeadId ?? '',
  },
  // {
  //   accessorKey: 'activeMandate',
  //   header: t('EstatesTableColumns.activeMandate'),
  //   cell: ({ row }) => {
  //     const estate = row.original
  //     const mandate = estate.mandate
  //     if (!mandate) return t('common.no')
  //     if (!mandate.mandate_media.length) return t('common.no')
  //     if (!mandate.orderNumber) return t('common.no')
  //     if (!mandate.mainSignDate) return t('common.no')
  //     return t('common.yes')
  //   },
  //   getGroupingValue: (estate) => getEstateStatusKey(estate),
  // },
  // {
  //   accessorKey: 'isEstimated',
  //   header: t('EstatesTableColumns.isEstimated'),
  //   cell: ({ row }) => {
  //     const estate = row.original
  //     const estimationPublishedDate = estate.estimationPublishedDate
  //     if (!estimationPublishedDate) return t('common.no')
  //     return t('common.yes')
  //   },
  //   getGroupingValue: (estate) => getEstateStatusKey(estate),
  // },
  {
    id: 'actions',
    enableGrouping: false,
    cell: ({ row }) => {
      const estate = row.original
      return <EstatesTableActionDropdown estate={estate} />
    },
  },
]
