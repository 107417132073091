import React, { forwardRef, useMemo, useState } from 'react'
import { StarsRating, StarsRatingProps } from '@/common/atoms/StarsRating'
import { useTranslation } from 'react-i18next'

export type ServiceRatingProps = StarsRatingProps
const ServiceRating = forwardRef<HTMLDivElement, ServiceRatingProps>(
  ({ max, value, onChange, ...props }, ref) => {
    const { t } = useTranslation()
    const [currentValue, setCurrentValue] = useState(value)
    const handleChange = (value: number) => {
      setCurrentValue(value)
      onChange?.(value)
    }
    const label = useMemo(() => {
      if (currentValue === 0) return t('ServiceRating.unknown')
      if (currentValue === 1) return t('ServiceRating.simple')
      if (currentValue === 2) return t('ServiceRating.standard')
      if (currentValue === 3) return t('ServiceRating.qualitative')
      if (currentValue === 4) return t('ServiceRating.veryQualitative')
    }, [currentValue, t])
    return (
      <StarsRating
        ref={ref}
        {...props}
        max={4}
        onChange={handleChange}
        value={currentValue}
        label={label}
      />
    )
  }
)

ServiceRating.displayName = 'ServiceRating'
export { ServiceRating }
