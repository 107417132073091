import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useToast } from '@/components/ui/use-toast'
import { trpc } from 'src/trpc'
import { Separator } from '@/components/ui/separator'
import { useStoreState } from '@store/hooks'
import AdminItem from './AdminItem'
import { useUserData } from '@nhost/react'
import { useCreateAgencyMemberMutation } from '@gql'
import useCurrentAgency from '@/agency/hooks/useCurrentAgency'
import { Heading } from '@/components/ui/heading'
import { Card, CardContent } from '@/components/ui/card'
import { MemberInfoFormValues } from '@/agencyMember/hooks/useMemberInfoSchema'
import { AdminAddForm } from './AdminAddForm'

export const AdminUsersList = () => {
  const { t } = useTranslation()
  const { toast } = useToast()
  const [loading, setLoading] = useState(false)
  const currentMember = useUserData()
  const agency = useCurrentAgency()
  const agencyMembers = useStoreState((state) => state.agencyMembers.entries)

  const [createAgencyMember] = useCreateAgencyMemberMutation()

  const handleInvite = async ({
    firstName,
    lastName,
    email,
  }: MemberInfoFormValues) => {
    setLoading(true)
    try {
      const inviteDate = new Date()
      const { data } = await createAgencyMember({
        variables: {
          inviteDate: inviteDate.toISOString(),
          agencyId: String(agency?.id),
          firstName,
          lastName,
          email,
        },
      })

      await trpc.agencyMember.inviteAgencyMember.mutate({
        agencyMemberId: String(data?.insert_agency_member_one?.id),
        email,
        inviterMember: currentMember?.displayName ?? currentMember?.email ?? '',
      })

      toast({
        title: t('AdminUsersList.inviteSuccess', {
          email: email,
        }),
        description: t('AdminUsersList.inviteSuccessDesc'),
        variant: 'success',
      })
    } catch (error) {
      toast({
        title: t('AdminUsersList.inviteFailed', {
          email,
        }),
        description: t('AdminUsersList.inviteFailedDesc'),
        variant: 'destructive',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="tw-space-y-3">
      <Heading>{t('AdminUsersList.heading')}</Heading>
      <p className="tw-max-w-[600px] tw-text-sm tw-text-gray-500">
        {t('AdminUsersList.description')}
      </p>
      <Card>
        <CardContent className="tw-mt-6">
          <AdminAddForm loading={loading} onClick={handleInvite} />
          <Separator className="tw-my-6" />
          <div>
            {!agencyMembers?.length && (
              <p className="tw-text-center tw-text-gray-500">
                {t('AdminUsersList.noAdmins')}
              </p>
            )}
            {agencyMembers?.map((agencyMember, index) => (
              <AdminItem key={index} {...agencyMember} />
            ))}
          </div>
        </CardContent>
      </Card>
    </div>
  )
}
