import { EstateForSalePageFragment } from '@gql'
import { InfoIcon } from 'lucide-react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { EstateSettingOpeningFormPrices } from './EstateSettingOpeningFormPrices'
import { EstateSettingOpeningFormDates } from './EstateSettingOpeningFormDates'

type EstateSettingOpeningFormProps = {
  estate: Pick<
    EstateForSalePageFragment,
    'id' | 'firstPrice' | 'reservePrice' | 'estateRounds'
  >
  readOnly?: boolean
}

export const EstateSettingOpeningForm = ({
  estate,
  readOnly,
}: EstateSettingOpeningFormProps) => {
  const { t } = useTranslation()
  return (
    <div className="tw-space-y-6 tw-h-full">
      <EstateSettingOpeningFormPrices estate={estate} readOnly={readOnly} />
      {!readOnly && (
        <div className="tw-py-3 tw-p-4 tw-bg-green-200 tw-rounded-lg">
          <p className="tw-text-sm tw-font-normal tw-text-green-700 tw-flex tw-items-center">
            <InfoIcon strokeWidth={3} className="tw-mr-3" />
            {t('EstateSettingOpeningForm.description')}
          </p>
        </div>
      )}
      <EstateSettingOpeningFormDates estate={estate} readOnly={readOnly} />
    </div>
  )
}
