import { Badge, BadgeProps } from '@/components/ui/badge'
import { WebappSimpleEstateFragment } from '@gql'
import { DEFAULT_DATE_FORMAT, parseDate } from '@almaris/shared/utils/dates'
import { TFunction } from 'i18next'
import React from 'react'
import { useTranslation } from 'react-i18next'

export enum EstateStatus {
  /** Procedure en cours */
  OnGoing = 'onGoing',
  MandateSigned = 'mandateSigned',
  AOInProgress = 'aoInProgress',
  AODone = 'aoDone',
  Archived = 'archived',
  ApplicationAccepted = 'applicationAccepted',
}
// TODO move into hasura ?
export const getEstateStatusKey = (
  estate: WebappSimpleEstateFragment
): keyof Config => {
  const { currentRoundId, mandate, archived } = estate
  const currentRound = estate.estateRounds.find(
    (round) => round.id === currentRoundId
  )

  if (!currentRound) return EstateStatus.OnGoing

  const now = new Date()
  const hasMandate = !!mandate?.mandate_media?.length
  const { startDate, endDate } = currentRound
  const parsedStartDate = parseDate(startDate, DEFAULT_DATE_FORMAT) ?? now
  const parsedEndDate = parseDate(endDate, DEFAULT_DATE_FORMAT) ?? now
  const isOnSale = estate.isSaleOpen
  const applicationAccepted = estate.applicationAcceptedDate != null

  if (archived) {
    return EstateStatus.Archived
  }

  if (applicationAccepted) {
    return EstateStatus.ApplicationAccepted
  }

  if (now.getTime() > parsedEndDate.getTime()) {
    return EstateStatus.AODone
  }

  // TODO: Should be
  // if (hasMandate && isOnSale && now.getTime() > parsedStartDate.getTime())
  // But we disabled the mandatory mandate for now
  if (isOnSale && now.getTime() > parsedStartDate.getTime()) {
    return EstateStatus.AOInProgress
  }

  return hasMandate ? EstateStatus.MandateSigned : EstateStatus.OnGoing
}
type ConfigEntry = BadgeProps & { label: string }
type Config = ReturnType<typeof getConfig>
const getConfig = (t: TFunction) => ({
  [EstateStatus.Archived]: {
    label: t(`EstateStatusBadge.archived`),
    variant: 'destructive',
    className: 'tw-ml-2',
  } as ConfigEntry,
  [EstateStatus.OnGoing]: {
    label: t(`EstateStatusBadge.onGoing`),
    variant: 'secondary',
  } as ConfigEntry,
  [EstateStatus.MandateSigned]: {
    label: t(`EstateStatusBadge.mandateSigned`),
  } as ConfigEntry,
  [EstateStatus.AOInProgress]: {
    label: t(`EstateStatusBadge.aoInProgress`),
  } as ConfigEntry,
  [EstateStatus.AODone]: {
    label: t(`EstateStatusBadge.aoDone`),
    variant: 'success',
  } as ConfigEntry,
  [EstateStatus.ApplicationAccepted]: {
    label: t(`EstateStatusBadge.applicationAccepted`),
    variant: 'success',
  } as ConfigEntry,
})

type EstateStatusBadgeProps = {
  estate: WebappSimpleEstateFragment
}

// - onGoing - En cours de complétion : au moment de la création du bien jusqu'à la signature du mandat
// - mandateSigned - Mandat signé : le mandat est chargé et la date de démarrage de la vente n'est pas encore atteint
// - aoInProgress - Appel d’offre en cours : Le mandat est chargé et la date de démarrage de la vente est lancé
// - aoDone - Appel d'offre terminé : la date de fin de la vente est échue
// - archived - archivé
export const EstateStatusBadge = ({ estate }: EstateStatusBadgeProps) => {
  const { t } = useTranslation()
  const config = getConfig(t)
  const key = getEstateStatusKey(estate)
  const { label, ...props } = config[key]

  return <Badge {...props}>{label}</Badge>
}
