import { format, parse, parseISO, Locale } from 'date-fns'
import { fr } from 'date-fns/locale'

export const DEFAULT_DATE_FORMAT = 'dd/MM/yyyy'
export const TABLE_DATE_FORMAT = 'PPy'

export const getDateWithoutTimezone = (value: Date) => {
  return new Date(
    value.getFullYear(),
    value.getMonth(),
    value.getDate(),
    value.getHours(),
    value.getMinutes() - value.getTimezoneOffset(),
    value.getSeconds(),
    value.getMilliseconds()
  )
}

const isValidDate = (value?: string | Date): value is string | Date => {
  if (!value) return false
  return !isNaN(new Date(value).getTime())
}

/***
 * If no format is provided, it will try to parse the date using the ISO format
 */
export const parseDate = (date: any, format?: string) => {
  if (!date) return
  try {
    const parsedDate = format ? parse(date, format, new Date()) : parseISO(date)
    if (isValidDate(parsedDate)) return parsedDate
    throw new Error()
  } catch (e) {
    if (isValidDate(date)) return new Date(date)
    return
  }
}

type FormatDateOptions = {
  locale?: Locale
  weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6
  firstWeekContainsDate?: 1 | 4
  useAdditionalWeekYearTokens?: boolean
  useAdditionalDayOfYearTokens?: boolean
  format?: string
}

export const formatDate = (
  date: Date | string | undefined | null,
  options?: FormatDateOptions
) => {
  const { format: _format, ...optionsWithDefault } = {
    locale: fr,
    format: 'P',
    ...options,
  }
  const parsedDate = parseDate(date)
  return parsedDate && format(parsedDate, _format, optionsWithDefault)
}
