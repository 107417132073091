import useIsAgencyMember from '@/agencyMember/hooks/useIsAgencyMember'
import { Card, CardContent } from '@/components/ui/card'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { InputDatePicker } from '@/components/ui/InputDatePicker'
import { InputPrice } from '@/components/ui/inputPrice'
import { Label } from '@/components/ui/label'
import { Textarea } from '@/components/ui/textarea'
import {
  EstateForMandatePageFragment,
  Mandate_Fee_Person_Enum,
  MandateFragment,
} from '@gql'
import { yupResolver } from '@hookform/resolvers/yup'
import { omit } from '@utils/omit'
import { format } from 'date-fns'
import { fr } from 'date-fns/locale'
import React, { useEffect, useMemo } from 'react'
import { DefaultValues, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  MandateInfoFormValues,
  useMandateInfoSchema,
} from '../hooks/useMandateInfoSchema'
import { MandateFeePersonSelect } from './MandateFeePersonSelect'
import { MandateTypeSelect } from './MandateTypeSelect'
import {
  generateObjectLabel,
  generateEstateLabel,
  generateRepresentativesAddress,
  generateRepresentativesNames,
} from '../utils/generateRegisterNumber'
import { currencyFieldDefaultProps } from '@almaris/shared/utils/formatCurrency'

type MandateInfoFormProps = {
  mandate?: MandateFragment | null
  estate: Pick<
    EstateForMandatePageFragment,
    'id' | 'type' | 'address' | 'zipCode' | 'city' | 'seller' | 'estate_sellers'
  >
  onSubmit: (
    values: Partial<MandateFragment>,
    mandateId?: MandateFragment['id']
  ) => Promise<string | null | undefined>
}

export const MandateInfoForm = ({
  mandate,
  estate,
  onSubmit,
}: MandateInfoFormProps) => {
  const { t } = useTranslation()
  const isAgencyMember = useIsAgencyMember()
  // If true, the mandate number has been generated and the mandate is now readonly (except for the mandate media and the signature date)
  const registryMandate = mandate?.mandate_registry
  const hasRegistryMandate = registryMandate != null

  const { schema } = useMandateInfoSchema()

  const availableSellers = useMemo(() => {
    const otherSellers = estate.estate_sellers.map((es) => es.seller)
    otherSellers.unshift(estate.seller)
    return otherSellers
  }, [estate.estate_sellers])

  const representativesNames = useMemo(() => {
    return hasRegistryMandate
      ? registryMandate.representativesNames
      : generateRepresentativesNames(availableSellers)
  }, [availableSellers, registryMandate, hasRegistryMandate])

  const representativesAddress = useMemo(() => {
    return hasRegistryMandate
      ? registryMandate.representativesAddress
      : generateRepresentativesAddress(availableSellers)
  }, [availableSellers, registryMandate, hasRegistryMandate])

  const mandateObjectLabel = useMemo(() => {
    return hasRegistryMandate
      ? registryMandate.objectLabel
      : generateObjectLabel(mandate)
  }, [registryMandate, mandate, hasRegistryMandate])

  const estateLabel = useMemo(() => {
    return hasRegistryMandate
      ? registryMandate.estateLabel
      : generateEstateLabel(estate)
  }, [estate, registryMandate, hasRegistryMandate])

  const defaultValues: DefaultValues<MandateInfoFormValues> = useMemo(() => {
    return {
      type: mandate?.type || undefined,
      orderNumber: registryMandate?.orderNumber || undefined,
      representativesNames: representativesNames || '',
      representativesAddress: representativesAddress || '',
      objectLabel: mandateObjectLabel || '',
      estateLabel: estateLabel || '',
      amendmentNumber: mandate?.amendmentNumber || '',
      precontractualDate: mandate?.precontractualDate
        ? new Date(mandate?.precontractualDate)
        : new Date(),
      mainSignDate: mandate?.mainSignDate
        ? new Date(mandate?.mainSignDate)
        : undefined,
      reservePrice: mandate?.reservePrice || undefined,
      firstPrice: mandate?.firstPrice || '',
      effectiveDate: mandate?.effectiveDate
        ? new Date(mandate?.effectiveDate)
        : undefined,
      initialDuration: mandate?.initialDuration || 6,
      renewalDuration: mandate?.renewalDuration || 3,
      maxRenewal: mandate?.maxRenewal || 6,
      fixedFee: mandate?.fixedFee || 3500,
      transactionalFee: mandate?.transactionalFee || 3.5,
      feePerson: mandate?.feePerson || Mandate_Fee_Person_Enum.Buyer,
      feeAmount: mandate?.feeAmount || 0,
      particularConditions: mandate?.particularConditions || '',
      earnedMoney: mandate?.earnedMoney || 10,
      earnedMoneyPaid: mandate?.earnedMoneyPaid || 5,
      cancellationDate: mandate?.cancellationDate
        ? new Date(mandate?.cancellationDate)
        : undefined,
      effectiveCancellationDate: mandate?.effectiveCancellationDate
        ? new Date(mandate?.effectiveCancellationDate)
        : undefined,
    }
  }, [
    mandate,
    registryMandate,
    representativesNames,
    representativesAddress,
    mandateObjectLabel,
    estateLabel,
  ])

  const form = useForm<MandateInfoFormValues>({
    resolver: yupResolver(schema),
    defaultValues,
  })
  const { handleSubmit, control, reset } = form

  // Auto saving the mandate
  useEffect(() => {
    reset(defaultValues)
  }, [defaultValues])
  useEffect(() => {
    const subscription = form.watch(() => {
      handleSubmit(async (values) => {
        // We omit some form properties which are not mapped to the database (if not Nhost will throw)
        const mandateValues = omit(
          values,
          'estateLabel',
          'objectLabel',
          'representativesNames',
          'representativesAddress'
        )
        await onSubmit(
          {
            ...mandateValues,
            mainSignDate: mandateValues.mainSignDate?.toISOString(),
            precontractualDate: mandateValues.precontractualDate?.toISOString(),
            effectiveDate: mandateValues.effectiveDate?.toISOString(),
            cancellationDate: mandateValues.cancellationDate?.toISOString(),
            effectiveCancellationDate:
              mandateValues.effectiveCancellationDate?.toISOString(),
          },
          mandate?.id
        )
      })()
    })

    return () => subscription.unsubscribe()
  }, [form, mandate])

  return (
    <Form {...form}>
      <Card>
        <CardContent className="tw-space-y-6 tw-pt-6">
          <FormField
            control={control}
            name="type"
            render={({ field }) => (
              <MandateTypeSelect
                field={field}
                disabled={!isAgencyMember || hasRegistryMandate}
              />
            )}
          />
          <div className="tw-flex tw-space-x-4">
            <FormField
              control={control}
              name="orderNumber"
              render={({ field }) => (
                <FormItem className="tw-w-1/2">
                  <FormLabel>
                    {t('MandateInfoForm.field.orderNumber')}
                  </FormLabel>
                  <FormControl>
                    <Input {...field} disabled />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="tw-w-1/2 tw-space-y-2">
              <Label htmlFor="generatedAt">
                {t('MandateInfoForm.field.generatedAt')}
              </Label>
              <Input
                id="generatedAt"
                disabled
                value={
                  registryMandate?.generatedAt
                    ? format(
                        new Date(registryMandate.generatedAt),
                        'dd MMMM yyyy HH:mm',
                        { locale: fr }
                      )
                    : ''
                }
              />
            </div>
          </div>

          <div className="tw-flex tw-space-x-4">
            <FormField
              control={control}
              name="representativesNames"
              render={({ field }) => (
                <FormItem className="tw-w-1/2">
                  <FormLabel>
                    {t('MandateInfoForm.field.representativesNames')}
                  </FormLabel>
                  <FormControl>
                    <Textarea {...field} disabled />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={control}
              name="representativesAddress"
              render={({ field }) => (
                <FormItem className="tw-w-1/2">
                  <FormLabel>
                    {t('MandateInfoForm.field.representativesAddress')}
                  </FormLabel>
                  <FormControl>
                    <Textarea {...field} disabled />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <div className="tw-flex tw-space-x-4 tw-w-full">
            <FormField
              control={control}
              name="objectLabel"
              render={({ field }) => (
                <FormItem className="tw-w-1/2">
                  <FormLabel>
                    {t('MandateInfoForm.field.objectLabel')}
                  </FormLabel>
                  <FormControl>
                    <Input {...field} disabled />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={control}
              name="estateLabel"
              render={({ field }) => (
                <FormItem className="tw-w-1/2">
                  <FormLabel>
                    {t('MandateInfoForm.field.estateLabel')}
                  </FormLabel>
                  <FormControl>
                    <Textarea {...field} disabled />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          {/* <FormField
            control={control}
            name="amendmentNumber"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  {t('MandateInfoForm.field.amendmentNumber')}
                </FormLabel>
                <FormControl>
                  <Input {...field} disabled={!isAgencyMember || hasOrderNumber} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          /> */}
          <div className="tw-flex tw-space-x-4">
            <FormField
              control={control}
              name="precontractualDate"
              render={({ field }) => (
                <FormItem className="tw-w-1/2">
                  <FormLabel>
                    {t('MandateInfoForm.field.precontractualDate')}
                  </FormLabel>
                  <FormControl>
                    <InputDatePicker
                      {...field}
                      fromYear={1900}
                      toYear={2100}
                      disabled={!isAgencyMember || hasRegistryMandate}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={control}
              name="mainSignDate"
              render={({ field }) => (
                <FormItem className="tw-w-1/2">
                  <FormLabel>
                    {t('MandateInfoForm.field.mainSignDate')}
                  </FormLabel>
                  <FormControl>
                    <InputDatePicker
                      {...field}
                      fromYear={1900}
                      toYear={2100}
                      // This field is readonly, the date is set while uploading the mandate
                      disabled={true}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="tw-flex tw-space-x-4">
            <FormField
              control={control}
              name="reservePrice"
              render={({ field }) => (
                <FormItem className="tw-w-1/2">
                  <FormLabel>
                    {t('MandateInfoForm.field.reservePrice')}
                  </FormLabel>
                  <FormControl>
                    <InputPrice
                      value={field.value}
                      onValueChange={(value) => field.onChange(value)}
                      disabled={!isAgencyMember || hasRegistryMandate}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={control}
              name="firstPrice"
              render={({ field }) => (
                <FormItem className="tw-w-1/2">
                  <FormLabel>{t('MandateInfoForm.field.firstPrice')}</FormLabel>
                  <FormControl>
                    <InputPrice
                      value={field.value}
                      onValueChange={(value) => field.onChange(value)}
                      disabled={!isAgencyMember || hasRegistryMandate}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="tw-flex tw-space-x-4">
            <FormField
              control={control}
              name="effectiveDate"
              render={({ field }) => (
                <FormItem className="tw-w-1/2">
                  <FormLabel>
                    {t('MandateInfoForm.field.effectiveDate')}
                  </FormLabel>
                  <FormControl>
                    <InputDatePicker
                      {...field}
                      fromYear={1900}
                      toYear={2100}
                      disabled={!isAgencyMember || hasRegistryMandate}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={control}
              name="initialDuration"
              render={({ field }) => (
                <FormItem className="tw-w-1/2">
                  <FormLabel>
                    {t('MandateInfoForm.field.initialDuration')}
                  </FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      disabled={!isAgencyMember || hasRegistryMandate}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <div className="tw-flex tw-space-x-4">
            <FormField
              control={control}
              name="renewalDuration"
              render={({ field }) => (
                <FormItem className="tw-w-1/2">
                  <FormLabel>
                    {t('MandateInfoForm.field.renewalDuration')}
                  </FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      disabled={!isAgencyMember || hasRegistryMandate}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={control}
              name="maxRenewal"
              render={({ field }) => (
                <FormItem className="tw-w-1/2">
                  <FormLabel>{t('MandateInfoForm.field.maxRenewal')}</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      disabled={!isAgencyMember || hasRegistryMandate}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="tw-flex tw-space-x-4">
            <FormField
              control={control}
              name="fixedFee"
              render={({ field }) => (
                <FormItem className="tw-w-1/2">
                  <FormLabel>{t('MandateInfoForm.field.fixedFee')}</FormLabel>
                  <FormControl>
                    <InputPrice
                      value={field.value}
                      onValueChange={(value) => field.onChange(value)}
                      disabled={!isAgencyMember || hasRegistryMandate}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={control}
              name="transactionalFee"
              render={({ field }) => (
                <FormItem className="tw-w-1/2">
                  <FormLabel>
                    {t('MandateInfoForm.field.transactionalFee')}
                  </FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      disabled={!isAgencyMember || hasRegistryMandate}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <FormField
            control={control}
            name="earnedMoney"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('MandateInfoForm.field.earnedMoney')}</FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    disabled={!isAgencyMember || hasRegistryMandate}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={control}
            name="earnedMoneyPaid"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  {t('MandateInfoForm.field.earnedMoneyPaid')}
                </FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    disabled={!isAgencyMember || hasRegistryMandate}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={control}
            name="feePerson"
            render={({ field }) => (
              <MandateFeePersonSelect
                field={field}
                disabled={!isAgencyMember || hasRegistryMandate}
              />
            )}
          />

          <FormField
            control={control}
            name="feeAmount"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('MandateInfoForm.field.feeAmount')}</FormLabel>
                <FormControl>
                  <InputPrice
                    value={field.value}
                    onValueChange={(value) => field.onChange(value)}
                    disabled={!isAgencyMember || hasRegistryMandate}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={control}
            name="particularConditions"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  {t('MandateInfoForm.field.particularConditions')}
                </FormLabel>
                <FormControl>
                  <Textarea
                    {...field}
                    disabled={!isAgencyMember || hasRegistryMandate}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <div className="tw-flex tw-space-x-4">
            <FormField
              control={control}
              name="cancellationDate"
              render={({ field }) => (
                <FormItem className="tw-w-1/2">
                  <FormLabel>
                    {t('MandateInfoForm.field.cancellationDate')}
                  </FormLabel>
                  <FormControl>
                    <InputDatePicker
                      {...field}
                      fromYear={1900}
                      toYear={2100}
                      disabled={!isAgencyMember}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={control}
              name="effectiveCancellationDate"
              render={({ field }) => (
                <FormItem className="tw-w-1/2">
                  <FormLabel>
                    {t('MandateInfoForm.field.effectiveCancellationDate')}
                  </FormLabel>
                  <FormControl>
                    <InputDatePicker
                      {...field}
                      fromYear={1900}
                      toYear={2100}
                      disabled={!isAgencyMember}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        </CardContent>
      </Card>
    </Form>
  )
}
