import { Button } from '@/components/ui/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { InputNumber } from '@/components/ui/inputNumber'
import { Label } from '@/components/ui/label'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { Switch } from '@/components/ui/switch'
import { useToast } from '@/components/ui/use-toast'
import {
  EstateSellerDataFormValues,
  useEstateSellerDataSchema,
} from '@/estate/hooks/useEstateSellerDataSchema'
import SellerCreateModal from '@/seller/modals/SellerCreateModal'
import { SellerMemberCombobox } from '@/sellerMember/components/SellerMemberCombobox'
import {
  EstateForSellersPageFragment,
  EstateSellerFragment,
  SellerFragment,
  useCreateEstateSellersMutation,
  useDeleteEstateSellersMutation,
  useUpdateEstateMutation,
  useUpdateEstateSellerMutation,
} from '@gql'
import { yupResolver } from '@hookform/resolvers/yup'
import { useHasuraClaim } from '@nhost/react'
import { uniqueFilter } from '@utils/array'
import debounce from 'lodash.debounce'
import { XIcon } from 'lucide-react'
import React, { memo, useCallback, useMemo, useState } from 'react'
import {
  useFieldArray,
  useForm,
  useFormContext,
  useWatch,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDeepCompareEffect } from 'use-deep-compare'

export type EstateSellerFormProps = {
  estate: Pick<
    EstateForSellersPageFragment,
    'id' | 'sellerId' | 'seller' | 'sellerData'
  >
  estateSellers: EstateSellerFragment[]
  otherSellers: Array<Pick<SellerFragment, 'id' | 'name' | 'archived'>>
  disabled?: boolean
}
export const EstateSellerForm = memo(function EstateSellerForm2({
  estate,
  estateSellers,
  otherSellers,
  disabled = false,
}: EstateSellerFormProps) {
  const { t } = useTranslation()
  const { toast } = useToast()

  const agencyMemberId = useHasuraClaim('agency-member-id')
  const readOnly = useMemo(
    () => disabled || agencyMemberId == null || agencyMemberId == 'null',
    [agencyMemberId, disabled]
  )

  const defaultValues: EstateSellerDataFormValues = useMemo(() => {
    // console.debug('defaultValues computed')
    return {
      sellerStructures: estateSellers.map((estateSeller) => ({
        sellerId: estateSeller.sellerId,
        ownershipShare: estateSeller.ownershipShare,
      })),
      singleRepresentativeForMultipleSellers:
        estate.sellerData?.singleRepresentativeForMultipleSellers ?? false,
      mandatedSellerWithPowerDelegation:
        estate.sellerData?.mandatedSellerWithPowerDelegation ?? '',
    }
  }, [
    estate.sellerData?.mandatedSellerWithPowerDelegation,
    estate.sellerData?.singleRepresentativeForMultipleSellers,
    estateSellers,
  ])

  const { schema } = useEstateSellerDataSchema()

  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  })

  const { control, handleSubmit /*, reset*/ } = form

  // useEffect(() => reset(defaultValues), [reset, defaultValues])

  const [updateEstate, { error: updateEstateError }] = useUpdateEstateMutation({
    onCompleted: () => {
      toast({
        variant: 'success',
        description: t('EstateDiagnosticsTab.toast.success'),
      })
    },
    onError: () => {
      toast({
        variant: 'destructive',
        description: t('EstateDiagnosticsTab.toast.error'),
      })
    },
  })
  const [createEstateSellerMutation, { error: createEstateSellerError }] =
    useCreateEstateSellersMutation({
      errorPolicy: 'ignore',
    })
  const [updateEstateSellerMutation, { error: updateEstateSellerError }] =
    useUpdateEstateSellerMutation()
  const [deleteEstateSellersMutation, { error: deleteEstateSellerError }] =
    useDeleteEstateSellersMutation()

  const [persistedValues, setPersistedValues] = useState(defaultValues)

  const persist = useCallback(
    async (
      values: EstateSellerDataFormValues,
      persistedValues: EstateSellerDataFormValues
    ) => {
      // console.debug('persisting...')
      // console.debug('persistedValues:', persistedValues)
      // console.debug('values:', values)

      updateEstate({
        variables: {
          id: estate.id,
          values: {
            sellerData: {
              singleRepresentativeForMultipleSellers:
                values.singleRepresentativeForMultipleSellers,
              mandatedSellerWithPowerDelegation:
                values.mandatedSellerWithPowerDelegation,
            },
          },
        },
      })

      const estateSellerStructures = persistedValues.sellerStructures ?? []
      const nextSellerStructures = values.sellerStructures ?? []
      const toCreate = nextSellerStructures
        .filter(
          (nextSellerStructure) =>
            !estateSellerStructures.find(
              (sellerStructure) =>
                sellerStructure.sellerId === nextSellerStructure.sellerId
            ) && nextSellerStructure.sellerId !== estate.sellerId
        )
        .map((item) => ({ ...item, estateId: estate.id }))

      const toUpdate = nextSellerStructures
        .filter(
          (nextSellerStructure) =>
            estateSellerStructures.find((sellerStructure) => {
              if (sellerStructure.sellerId !== nextSellerStructure.sellerId)
                return false
              if (
                sellerStructure.ownershipShare ===
                nextSellerStructure.ownershipShare
              )
                return false
              return true
            }) && nextSellerStructure.sellerId !== estate.sellerId
        )
        .map((item) => ({ ...item, estateId: estate.id }))

      const toDelete = estateSellerStructures.filter(
        (sellerStructure) =>
          !nextSellerStructures.find(
            (nextSellerStructure) =>
              nextSellerStructure.sellerId === sellerStructure.sellerId
          ) && sellerStructure.sellerId !== estate.sellerId
      )

      if (toCreate.length > 0) {
        // console.debug('we should call createEstateSellersMutation')
        createEstateSellerMutation({
          variables: {
            valuesToCreate: toCreate,
          },
        })
      }

      if (toUpdate.length > 0) {
        // console.debug('we should call updateEstateSellerMutation')
        toUpdate.forEach((item) => {
          updateEstateSellerMutation({ variables: item })
        })
      }

      if (toDelete.length > 0) {
        // console.debug('we should call deleteEstateSellersMutation')
        deleteEstateSellersMutation({
          variables: {
            sellerIdsToDelete: toDelete.map(
              (sellerStructure) => sellerStructure.sellerId
            ),
            estateId: estate.id,
          },
        })
      }

      setPersistedValues(values)

      // console.debug('persisted')
    },
    [
      estate.id,
      estate.sellerId,
      updateEstate,
      createEstateSellerMutation,
      updateEstateSellerMutation,
      deleteEstateSellersMutation,
    ]
  )

  const values = useWatch({ control })

  useDeepCompareEffect(() => {
    let debouncedFunc: ReturnType<typeof debounce> | undefined = undefined

    // console.debug('handleSubmit')
    handleSubmit((validValues) => {
      const prev = JSON.stringify(persistedValues)
      const next = JSON.stringify(validValues)

      // TODO use deep compare instead
      if (next === prev) return

      // console.debug('handleSubmit | valid, calling debounced persist')
      // TODO use deep copy instead
      const nextValues = JSON.parse(next)
      debouncedFunc = debounce(() => persist(nextValues, persistedValues), 1000)
      debouncedFunc()
    })()

    return () => debouncedFunc?.cancel()
    // NOTE "values" is not used here, but it is needed to trigger the effect on any change
  }, [persistedValues, values, handleSubmit, persist])

  return (
    <Form {...form}>
      <form className="tw-flex tw-flex-col tw-gap-4">
        <EstateSeller estateSellerName={estate.seller.name} />
        <SellerStructures
          estateSellerId={estate.sellerId}
          otherSellers={otherSellers}
          readOnly={readOnly}
        />
        <SingleRepresentativeForMultipleSellers
          estateSellerId={estate.sellerId}
          readOnly={readOnly}
        />
      </form>
    </Form>
  )
})

const EstateSeller = memo(function EstateSeller({
  estateSellerName,
}: {
  estateSellerName: string
}) {
  //console.debug('RENDER EstateSeller')

  const { t } = useTranslation()
  const { control } = useFormContext<EstateSellerDataFormValues>()

  const formSellerStructures = useWatch({
    name: 'sellerStructures',
    control,
  })

  const estateSellerOwnershipShare = useMemo(() => {
    if (formSellerStructures == null) return 100
    return (
      100 - formSellerStructures.reduce((acc, s) => acc + s.ownershipShare, 0)
    )
  }, [formSellerStructures])

  return (
    <div className="tw-flex tw-flex-row tw-gap-4 tw-align-center tw-justify-between tw-items-center">
      <Label>{estateSellerName}</Label>
      <Label className="tw-text-muted-foreground tw-flex tw-flex-col tw-items-start tw-gap-3">
        {t('EstateSellerDataForm.ownershipShare')}
        <InputNumber value={estateSellerOwnershipShare} disabled />
      </Label>
    </div>
  )
})

const SellerStructures = memo(function SellerStructures({
  estateSellerId,
  otherSellers,
  readOnly,
}: {
  estateSellerId: string
  otherSellers: Array<Pick<SellerFragment, 'id' | 'name' | 'archived'>>
  readOnly: boolean
}) {
  const { t } = useTranslation()
  const { control } = useFormContext<EstateSellerDataFormValues>()

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'sellerStructures',
  })

  const estateSellerOwnershipShare = useMemo(() => {
    if (fields == null) return 100
    return 100 - fields.reduce((acc, s) => acc + s.ownershipShare, 0)
  }, [fields])

  const availableSellers = useMemo(() => {
    return otherSellers.filter(
      (seller) =>
        !seller.archived &&
        fields.find((f) => f.sellerId === seller.id) == undefined
    )
  }, [otherSellers, fields])

  const addSellerStructure = useCallback(
    (sellerId: string) => {
      append({
        sellerId,
        ownershipShare: estateSellerOwnershipShare / 2,
      })
    },
    [estateSellerOwnershipShare, append]
  )

  const getSellerLabel = useCallback(
    (sellerId: string) =>
      otherSellers?.find((seller) => seller.id === sellerId)?.name ?? '',
    [otherSellers]
  )

  return (
    <>
      {fields.map(({ id, sellerId }, index: number) => (
        <div
          key={id}
          className="tw-flex tw-flex-row tw-gap-4 tw-align-center tw-justify-between tw-items-center"
        >
          {!readOnly && (
            <Button type="button" onClick={() => remove(index)}>
              <XIcon className="tw-size-3 tw-stroke-current" />
            </Button>
          )}
          <Label>{getSellerLabel(sellerId)}</Label>
          <FormField
            name={`sellerStructures.${index}.ownershipShare`}
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  {t('EstateSellerDataForm.ownershipShare')}
                </FormLabel>
                <FormControl>
                  <InputNumber
                    {...field}
                    min={0}
                    max={100}
                    disabled={readOnly}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
      ))}
      {!readOnly && (
        <>
          <Select value="" onValueChange={addSellerStructure}>
            <SelectTrigger>
              <SelectValue placeholder={t('SellerSelect.placeholder')} />
            </SelectTrigger>
            <SelectContent>
              {availableSellers.length === 0 ? (
                <SelectGroup>
                  <SelectLabel>{t('SellerSelect.noResult')}</SelectLabel>
                </SelectGroup>
              ) : (
                <>
                  {availableSellers.map((seller) => (
                    <SelectItem key={seller.id} value={seller.id}>
                      {seller.name}
                    </SelectItem>
                  ))}
                </>
              )}
            </SelectContent>
          </Select>
          <SellerCreateModal onSuccess={(sf) => addSellerStructure(sf.id)} />
        </>
      )}
    </>
  )
})

const SingleRepresentativeForMultipleSellers = memo(
  function SingleRepresentativeForMultipleSellers({
    estateSellerId,
    readOnly,
  }: {
    estateSellerId: string
    readOnly: boolean
  }) {
    const { t } = useTranslation()
    const { control } = useFormContext<EstateSellerDataFormValues>()

    const singleRepresentativeForMultipleSellers = useWatch({
      control,
      name: 'singleRepresentativeForMultipleSellers',
      // defaultValue: false,
    })

    const sellerStructures = useWatch({
      control,
      name: 'sellerStructures',
      // defaultValue: [],
    })

    const involvedSellerIds = useMemo(() => {
      return [
        estateSellerId,
        ...(sellerStructures?.map((ss) => ss.sellerId) ?? []),
      ]
        .filter((id) => id != null)
        .filter(uniqueFilter)
    }, [estateSellerId, sellerStructures])

    return (
      <>
        {!readOnly && (
          <FormField
            control={control}
            name={`singleRepresentativeForMultipleSellers`}
            render={({ field: { value, onChange, ...field } }) => (
              <FormItem className="tw-flex tw-flex-row tw-gap-4 tw-items-center">
                <FormLabel>
                  {t(
                    'EstateSellerDataForm.singleRepresentativeForMultipleSellers'
                  )}
                </FormLabel>
                <FormControl>
                  <Switch
                    className="!tw-mt-0"
                    {...field}
                    checked={value}
                    onCheckedChange={onChange}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        )}
        {singleRepresentativeForMultipleSellers && (
          <FormField
            control={control}
            name="mandatedSellerWithPowerDelegation"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  {t('EstateSellerDataForm.mandatedSellerWithPowerDelegation')}
                </FormLabel>
                <FormControl>
                  <SellerMemberCombobox
                    {...field}
                    sellerIds={involvedSellerIds}
                    disabled={readOnly}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        )}
      </>
    )
  }
)
