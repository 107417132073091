import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { HousePlusIcon } from 'lucide-react'
import React from 'react'
import { EstateAddForm } from './EstateAddForm'
import { useTranslation } from 'react-i18next'
import useIsAgencyMember from '@/agencyMember/hooks/useIsAgencyMember'
import { useNavigate } from 'react-router-dom'
import { SellerEstateAddForm } from './SellerEstateAddForm'
import { useSellerMemberId } from '@/seller/hooks/useSellerMemberId'

type EstateAddFormModalProps = {
  isOpen: boolean
  setIsOpen: (open: boolean) => void
}

export const EstateAddFormModal = ({
  isOpen,
  setIsOpen,
}: EstateAddFormModalProps) => {
  const { t } = useTranslation()
  const isAgencyMember = useIsAgencyMember()
  const sellerMemberId = useSellerMemberId()
  const navigate = useNavigate()
  const handleEstateCreate = (id: string) => {
    setIsOpen(false)
    navigate(`/estates/${id}`)
  }

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <Button variant={isAgencyMember ? 'default' : 'green'}>
          <HousePlusIcon className="tw-h-4 tw-w-4 tw-mr-2" />
          {isAgencyMember
            ? t('EstatesAddForm.addEstate')
            : t('EstatesAddForm.addEstateSeller')}
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t('EstatesAddForm.addEstate')}</DialogTitle>
        </DialogHeader>
        {isAgencyMember ? (
          <EstateAddForm onCreate={handleEstateCreate} />
        ) : sellerMemberId ? (
          <SellerEstateAddForm onCreate={handleEstateCreate} />
        ) : (
          <></>
        )}
      </DialogContent>
    </Dialog>
  )
}
