import { Title } from '@/common/atoms/Title'
import React from 'react'
import { useTranslation } from 'react-i18next'
import useQueryParams from '../hooks/useQueryParams'
import { Button } from '@/components/ui/button'
import { useNhostClient } from '@nhost/react'

type Params = {
  ticket: string
  type: string
  redirectTo: string
  token: string
}

const EmailVerify = () => {
  const nhost = useNhostClient()
  const { t } = useTranslation()
  const { ticket, type, redirectTo, token } = useQueryParams<Params>()

  // We can't encode the redirectTo param as NHost won't accept encoded URI
  // As the URI may contain additional parameters, we need to extract the redirectTo param from the URL
  const url = new URL(window.location.href).toString()
  const redirectSplit = url.split('redirectTo=')[1]

  // Let's remove nhost params
  const completeRedirectURL = redirectSplit ? new URL(redirectSplit) : undefined

  completeRedirectURL?.searchParams.delete('type')
  completeRedirectURL?.searchParams.delete('ticket')

  // We add the token to the URL to encode the whole URL later and ensure the token is passed
  if (token && !completeRedirectURL?.searchParams.has('token')) {
    completeRedirectURL?.searchParams.append('token', token)
  }

  const finalRedirectURL = completeRedirectURL
    ? encodeURIComponent(completeRedirectURL?.toString())
    : ''

  const buttonLink = `${nhost.auth.url}/verify?ticket=${ticket}&type=${type}&redirectTo=${finalRedirectURL}`

  const redirectToVerificationLink = () => {
    if (ticket && type && redirectTo) {
      window.location.href = buttonLink
    }
  }

  return (
    <div className="tw-space-y-5 tw-mb-5">
      <Title>{t('InvitePage.title')}</Title>
      <h1 className="tw-text-2xl tw-font-bold">{t('InvitePage.heading')}</h1>
      <Button onClick={redirectToVerificationLink} variant="green">
        {t('InvitePage.verify')}
      </Button>
    </div>
  )
}

export default EmailVerify
